import React, { useState, forwardRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Snackbar,
  Grid,
  Box
  // LinearProgress
} from "@mui/material";
import MUITableColumns from "./MUITableColumns/MUITableColumn";
import MUIDataTable from "mui-datatables";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery, useTheme } from "@mui/material";
import "./TruckSchedule.css";
import { getScheduledTruckItems } from "../../redux-sliceRoutes/truckScheduleRoutes";
import OpenScheduleCustomToolbar from "./CustomeToolbarSelect/OpenScheduleCustomToolbar";
import moment from "moment";
import "./TruckSchedule.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TruckSchedule_v2 = () => {
  const { truckTableColumns } = MUITableColumns;

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [scheduleData, setScheduleData] = useState([]);

  const GetScheduledOpenOrders = useCallback(() => {
    setLoading(true);
    dispatch(getScheduledTruckItems()).then((response) => {
      if (response.type === "getScheduledTruckItems/fulfilled") {
        response.payload.forEach(
          (items) =>
            (items.pickup_date = moment(items.pickup_date).format("MM-DD-YYYY"))
        );
        setScheduleData(response.payload);
        setLoading(false);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    GetScheduledOpenOrders();
  }, [GetScheduledOpenOrders]);

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const getOptions = () => {
    return {
      filter: true,
      selectableRows: "multiple",
      responsive: isMobile ? "scrollMaxHeight" : "standard",
      filterType: "multiselect",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <OpenScheduleCustomToolbar
          displayData={displayData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          data={scheduleData}
          setCSVFileData={setScheduleData}
          setSnackBarStatus={setSnackBarStatus}
        />
      )
    };
  };
  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      {!loading ? (
        <div className="Container">
          <Grid container>
            <Box ml={3}>
              <Alert
                className="open_schedule_truck_alert_info"
                icon={false}
                sx={{
                  backgroundColor: "#87CEFA",
                  color: "black",
                  height: "80px"
                }}
              >
                <span style={{ display: "inline" }}>
                  <FontAwesomeIcon icon={faTriangleExclamation} size="2x" />
                  &nbsp;&nbsp;
                  <Typography
                    variant="h4"
                    style={{ lineHeight: "2", display: "inline-block" }}
                  >
                    When canceling/unscheduling orders, make sure to contact
                    production to void law tags!
                  </Typography>
                </span>
              </Alert>
            </Box>
          </Grid>
          <MUIDataTable
            className="data-table"
            title={
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h4">
                    Open Scheduled Truck Orders
                  </Typography>
                </Grid>
              </Grid>
            }
            options={getOptions()}
            columns={truckTableColumns}
            data={scheduleData}
          />
        </div>
      ) : (
        <div className="Container">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "60vh" }}
          >
            <Grid item xs={6}>
              {/* <LinearProgress className="loading" /> */}
              <div className="car-movement">
                <div className="car">🚚.. 🚛.. 🚚.. 🚛..</div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default TruckSchedule_v2;
