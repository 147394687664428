import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Button,
  Grid,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Table,
  TableBody
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export default function FoamSkuDialog(props) {
  const [skuInfo, setskuInfo] = useState([]);

  const handleClose = (event, reason) => {
    props.close(false);
  };

  useEffect(() => {
    if (props.skuData.length && props.open === true) {
      setskuInfo(props.skuData[0]);
      let filteredData = props.skuData;
      filteredData.filter((data) => {
        let dataObj = {
          sku: data.parent.sku,
          description: data.parent.description,
          cure_time_days: data.parent.cure_time_days,
          width_in: data.parent.width_in
        };
        return dataObj;
      });
    }
  }, [props.open, props.skuData]);

  return (
    <>
      {skuInfo.parent ? (
        <>
          <Dialog
            data-testid="long-bun-dialog"
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="long-bun-dialog"
            PaperProps={{
              style: {
                maxWidth: "3000px ",
                minWidth: "1400px ",
                backgroundColor: "none",
                marginTop: "-0px"
              }
            }}
          >
            <DialogContent id="long-bun-dialog-content">
              <Typography
                component={"span"}
                variant="h4"
                className="t8_production_dialog_title"
              >
                Foam Sku Created
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Box mt={5}>
                    <Typography
                      variant="h6"
                      className="t8_production_dialog_subtitle"
                    >
                      Long Bun Sku:
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      SKU:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {skuInfo.parent.sku}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Description:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {skuInfo.parent.description}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Cure Time:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {skuInfo.parent.cure_time_days}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Width:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {` ${skuInfo.parent.width_in}(in)`}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    className="t8_production_dialog_subtitle"
                  >
                    T8 Skus
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="large">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Short Bun SKU's</StyledTableCell>
                          <StyledTableCell>Description</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {skuInfo.children.map((T8) => (
                        <TableBody key={T8.id}>
                          <TableRow>
                            <StyledTableCell
                              className="t8_production_dialog_table_cell"
                              component="th"
                              align="left"
                            >
                              {T8.sku}
                            </StyledTableCell>
                            <StyledTableCell component="th" align="left">
                              {T8.description}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="alert-button-div">
              <Button
                size="large"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
                onClick={handleClose}
                className="addBunLineButton"
              >
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        ""
      )}
    </>
  );
}
