import { createAsyncThunk } from "@reduxjs/toolkit";
import schedule_service_api from "../utils/schedule_service_api";
import { getToken } from "../utils/auth";

// route for retrieving schedule by date
export const getTruckSchedule = createAsyncThunk(
  "getTruckSchedule",
  async (queryParams = {}) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/truck_schedule/scheduled_items`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("getSchedule error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return errorPayload;
      });
  }
);

// route for email csv file of unscheduled items
export const emailTruckCSVSchedule = createAsyncThunk(
  "emailTruckCSVSchedule",
  async (queryParams = {}, { rejectWithValue }) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/truck_schedule/email_truck_stock_orders`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("emailCSVSchedule error", error);
        const errorPayload = {
          message: error.response.data.detail,
          status: error.response.status
        };
        console.log(errorPayload);
        return rejectWithValue(errorPayload);
      });
  }
);

// route for submitting csv upload file
export const submitTruckCSVSchedule = createAsyncThunk(
  "submitCSVSchedule",
  async (data, { rejectWithValue }) => {
    const { email, csvFileData, scheduled_date } = data;
    const token = await getToken();
    const params = { email, scheduled_date };

    return schedule_service_api
      .post(`/truck_schedule/submit_schedule`, csvFileData, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorPayload = {
          message: error.response.data.detail,
          status: error.response.status
        };
        console.log(errorPayload);
        return rejectWithValue(errorPayload);
      });
  }
);

// route for soft deleting schedule items
export const unscheduledTruckItems = createAsyncThunk(
  "unscheduledTruckItems",
  async ({ user_email, items }, { rejectWithValue }) => {
    if (!Array.isArray(items)) {
      return rejectWithValue("Invalid data format: items should be an array");
    }
    // Filter out items with null IDs
    let filteredUnscheduledItemsArray = items.filter(
      (item) => item.id !== null
    );
    // Prepare the payload in the required format
    const payload = { items: filteredUnscheduledItemsArray };
    try {
      const token = await getToken();
      const response = await schedule_service_api.post(
        `/truck_schedule/unschedule_items/${user_email}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("unscheduleItems error", error);
      // Properly format the error response
      const errorPayload = {
        message: error.response?.data?.detail || "An unexpected error occurred",
        status: error.response?.status || 500
      };
      console.log("Error payload:", errorPayload);
      return rejectWithValue(errorPayload);
    }
  }
);

// route for expoeting schedule by date to ohm
export const exportToOhm = createAsyncThunk(
  "exportToOhm",
  async (queryParams) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .post(`/truck_schedule/export_to_ohm`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("exportToOhm error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return errorPayload;
      });
  }
);

//route for getting unschedule truck items
export const getUnscheduledTruckItems = createAsyncThunk(
  "getUnscheduledTruckItems",
  async (queryParams = {}) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/truck_schedule/all_unscheduled_truck_items`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("getUnscheduledTruckItems error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return errorPayload;
      });
  }
);
//route for getting unschedule truck items
export const getScheduledTruckItems = createAsyncThunk(
  "getScheduledTruckItems",
  async (queryParams = {}) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/truck_schedule/open_scheduled_truck_orders`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("getScheduledTruckItems error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return errorPayload;
      });
  }
);
