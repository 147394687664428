import React, { useEffect, useState, useRef, forwardRef } from "react";
import { useDispatch } from "react-redux";
import {
  Tooltip,
  TextField,
  IconButton,
  CircularProgress,
  Snackbar,
  LinearProgress,
  Grid
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import MuiAlert from "@mui/material/Alert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveIcon from "@mui/icons-material/Save";
import MailIcon from "@mui/icons-material/Mail";
import { useMediaQuery, useTheme } from "@mui/material";
import Papa from "papaparse";
import scheduleTableColumns from "./MUITableColumns/MUITableColumns";
import MyCustomToolbarSelect from "./CustomToolbarSelect/MyCustomToolbarSelect";
import "./D2CSchedule.css";
import {
  getSchedule,
  emailCSVSchedule,
  submitCSVSchedule,
  exportToOhm
} from "../../redux-sliceRoutes/scheduleRoutes";
import { slackMessage } from "../../redux-sliceRoutes/slackRoutes";
import { msalInstance } from "../../index";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const D2CSchedule = () => {
  const dispatch = useDispatch();
  const todaysDate = new Date().toISOString().slice(0, 10);
  const fileInputRef = useRef(null);
  const [scheduleDate, setScheduleDate] = useState(todaysDate);
  const [CSVFileData, setCSVFileData] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [emailScheduleInProgress, setEmailScheduleInProgress] = useState(false);
  const [emailScheduleSuccess, setEmailScheduleSuccess] = useState(false);
  const [
    saveScheduleButtonDisabledStatus,
    setSaveScheduleButtonDisabledStatus
  ] = useState(true);
  const [requestDate, setRequestDate] = useState(todaysDate);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [timeDataRequest, setTimeDataRequest] = useState(null);

  useEffect(() => {
    const requestSchedule = async (date) => {
      console.time("Time taken to retrieve data:");
      console.time("Time taken to render data:");

      setTimeDataRequest(Date.now());
      setLoading(true);
      try {
        const response = await dispatch(getSchedule({ scheduled_date: date }));
        
        if (response.type === "getSchedule/fulfilled") {
          setCSVFileData(response.payload.order_items);
          console.timeEnd("Time taken to retrieve data:");
        } else if (response.type === "getSchedule/rejected") {
          if (response.payload.error.detail) {
            setCSVFileData(response.payload.error.detail.valid_items);
            // slack message
            const env = process.env.REACT_APP_ENV;
            const message = `${env} - Schedule App FE:\n` + response.payload.error.detail.errors;
            const res = dispatch(slackMessage({ message: message }));
            const errorMsg = response.payload.error.detail.errors;
            console.log("Error Msgs: " + errorMsg);

            setSnackBarStatus({
              open: true,
              message: (
                <>
                  {errorMsg.map((error, index) => (
                    <React.Fragment key={index}>
                      {error}
                      <br />
                    </React.Fragment>
                  ))}
                  Contact IT
                </>
              ),
              severity: "error"
            });
          }
        }
      } catch (error) {
        console.error(`Error fetching schedule: ${error}`);
        setCSVFileData([]);
        setSnackBarStatus({
          message: (
            <>
              {error}
              <br />
              Contact IT
            </>
          ),
          severity: "error"
        });
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    requestSchedule(requestDate);
  }, [requestDate, dispatch]);

  useEffect(() => {
    if (CSVFileData.length > 0 && timeDataRequest) {
      console.timeEnd("Time taken to render data:");
    }
  }, [CSVFileData, timeDataRequest]);

  // handles custom toolbar date picker
  const handleScheduleDateChange = (e) => {
    setScheduleDate(e.target.value);
    setRequestDate(e.target.value);
  };

  // handle export to ohm request
  const requestExportToOhm = async (date) => {
    await dispatch(exportToOhm({ scheduled_date: date })).then((response) => {
      if (response.type === "exportToOhm/fulfilled") {
        setSnackBarStatus({
          open: true,
          message: response.payload.message,
          severity: "info"
        });
      }
    });
  };

  // handles custom toolbar save icon
  const handleSaveSchedule = async (e) => {
    let userAccount = msalInstance.getActiveAccount();
    setSaveScheduleButtonDisabledStatus(true); // Disable the save button
    setSnackBarStatus({
      open: true,
      message: "Scheduling items",
      severity: "info"
    });

    try {
      const response = await dispatch(
        submitCSVSchedule({
          email: userAccount.username,
          csvFileData: CSVFileData,
          scheduled_date: scheduleDate
        })
      );

      if (response.type === "submitCSVSchedule/fulfilled") {
        setSnackBarStatus({
          open: true,
          message: response.payload.message,
          severity: "info"
        });
        requestExportToOhm(requestDate);
      } else {
        setSnackBarStatus({
          open: true,
          message: response.error.message,
          severity: "error"
        });
      }
    } catch (error) {
      setSnackBarStatus({
        open: true,
        message: error.message,
        severity: "error"
      });
    } finally {
      setSaveScheduleButtonDisabledStatus(false); // Enable the save button
    }
  };

  // Trigger click on the hidden file input when the button is clicked
  const handleUploadFile = () => {
    fileInputRef.current.click();
  };

  // email open order csv to user
  const emailOpenOrderCSV = async () => {
    try {
      setEmailScheduleInProgress(true);
      let userAccount = msalInstance.getActiveAccount();
      await dispatch(emailCSVSchedule({ email: userAccount.username })).then(
        (response) => {
          if (response.type === "emailCSVSchedule/fulfilled") {
            setEmailScheduleSuccess(true);

            // set snack bar to message
            setSnackBarStatus((prevState) => ({
              ...prevState,
              open: true,
              message: response.payload.message,
              severity: "info"
            }));
          } else {
            setEmailScheduleSuccess(false);
            // set snack bar to error
            setSnackBarStatus((prevState) => ({
              ...prevState,
              open: true,
              message: response.payload.message,
              severity: "error"
            }));
          }
        }
      );
    } finally {
      setEmailScheduleInProgress(false);
    }
  };

  // Function to check if a row is empty
  const isRowEmpty = (row) => {
    return Object.values(row).every((value) => value === null || value === "");
  };

  // Function to handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      readFile(file);
    } else {
      setSnackBarStatus({
        open: true,
        message: "Error uploading file",
        severity: "error"
      });
    }
  };

  // Function to read the file
  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      parseCSV(csvData);
    };
    reader.readAsText(file);
  };

  // Function to parse CSV data using PapaParse
  const parseCSV = (csvData) => {
    Papa.parse(csvData, {
      header: true,
      dynamicTyping: true,
      complete: handleParsedData,
      error: handleParseError
    });
  };

  // Callback function for successful parsing
  const handleParsedData = (parsedData) => {
    const nonEmptyRows = parsedData.data.filter((row) => !isRowEmpty(row));
    const processedData = processRows(nonEmptyRows);
    const { stockItems, nonStockItems } = filterItems(processedData);
    const uploadedSchedule = nonStockItems.concat(stockItems);
    setCSVFileData(uploadedSchedule);
    setSaveScheduleButtonDisabledStatus(false);
  };

  // Callback function for parsing error
  const handleParseError = (error) => {
    console.error("Error parsing CSV:", error.message);
    // Optionally handle error state or notify user
  };

  // Function to process each row of data
  const processRows = (rows) => {
    return rows.map((item, index) => {
      const processedItem = processItem(item, index);
      return processedItem;
    });
  };

  // Function to process each individual item in a row
  const processItem = (item, index) => {
    const processedItem = { ...item }; // Copy item to avoid mutation

    // Convert each property to string if not already
    for (const key in processedItem) {
      if (Object.prototype.hasOwnProperty.call(processedItem, key)) {
        if (
          typeof processedItem[key] !== "string" &&
          processedItem[key] !== null &&
          processedItem[key] !== undefined
        ) {
          processedItem[key] = processedItem[key].toString();
        } else if (typeof processedItem[key] === "string") {
          processedItem[key] = processedItem[key].replace(/^'/, "");
        }
      }
    }

    // Convert 'order_item_type' to uppercase
    if (processedItem["order_item_type"]) {
      processedItem["order_item_type"] =
        processedItem["order_item_type"].toUpperCase();
    }

    return processedItem;
  };

  // Function to filter stock and non-stock items
  const filterItems = (data) => {
    const stockItems = data.filter(
      (item) =>
        item.order_item_type === "H" && item.order_item_type !== undefined
    );
    const nonStockItems = data.filter(
      (item) =>
        item.order_item_type !== "H" || item.order_item_type === undefined
    );
    return { stockItems, nonStockItems };
  };

  // data table toolbar options
  const getOptions = () => {
    return {
      filter: true,
      selectableRows: "multiple",
      responsive: isMobile ? "scrollMaxHeight" : "standard",
      filterType: "dropdown",
      rowsPerPage: 100,
      print: false,
      download: true,
      viewColumns: false,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <MyCustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          data={CSVFileData}
          setCSVFileData={setCSVFileData}
          setSnackBarStatus={setSnackBarStatus}
        />
      ),
      //created custom tool bar with save, date picker and switch for date range selection
      customToolbar: () => {
        return (
          <>
            <Tooltip title="Email Schedule">
              {emailScheduleInProgress ? (
                <CircularProgress className="email-progress-circle" />
              ) : (
                <IconButton onClick={emailOpenOrderCSV}>
                  <MailIcon
                    className="mail-icon"
                    sx={{ color: emailScheduleSuccess ? "green" : "" }}
                  />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title="Upload File">
              <IconButton onClick={handleUploadFile}>
                <FileUploadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload File">
              <input
                type="file"
                className="upload_file_input"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".csv"
              />
            </Tooltip>
            <Tooltip title="Save Schedule">
              <span>
                <IconButton
                  onClick={handleSaveSchedule}
                  disabled={saveScheduleButtonDisabledStatus}
                >
                  <SaveIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip>
              <TextField
                className="date-picker"
                type="date"
                label="Schedule Date"
                name="date"
                variant="outlined"
                value={scheduleDate}
                onChange={handleScheduleDateChange}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Tooltip>
          </>
        );
      }
    };
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      {!loading ? (
        <div className="Container">
          <MUIDataTable
            className="data-table"
            title="Production Schedule"
            options={getOptions()}
            columns={scheduleTableColumns}
            data={CSVFileData}
          />
        </div>
      ) : (
        <div className="Container">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "60vh" }}
          >
            <Grid item xs={6}>
              <LinearProgress className="loading" />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default D2CSchedule;
