import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const slackMessage = createAsyncThunk(
  "slackMessage",
  async (queryParams, { rejectWithValue }) => {
    const slackToken = process.env.REACT_APP_SLACK_BOT_TOKEN;
    const slackChannel = process.env.REACT_APP_SLACK_CHANNEL;

    if (!slackToken) {
      throw new Error("Slack token not found");
    }

    const payload = {
      channel: slackChannel,
      text: queryParams.message
    };

    return axios
      .post(
        `https://slack.com/api/chat.postMessage`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${slackToken}`,
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        if (!response.data.ok) {
          throw new Error(response.data.error);
        }
        return response.data;
      })
      .catch((error) => {
        console.error("slackMessage error", error);
        const errorPayload = {
          message: error.message,
          status: error.response ? error.response.status : 500
        };
        return rejectWithValue(errorPayload);
      });
  }
);
