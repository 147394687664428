import React, { useEffect, useState, forwardRef } from "react";
import "./longBunDialog.css";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Input,
  Select,
  MenuItem,
  Snackbar,
  Autocomplete,
  TextField,
  Divider,
  Box
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { longBunSkus } from "../actions.js/longBunActions"; // Replace with the actual path to your Redux slice

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LongBunModal(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.foam.data); // Replace 'data' with your actual state slice name
  const {
    openStatus,
    setLongBunDialog,
    setScheduleData,
    editTableRowStatus,
    setEditTableRowStatus,
    selectedEditRow,
    selectedEditRowIndex,
    scheduleData
  } = props;
  const [longBunData, setLongBunData] = useState({
    sku: "",
    quantity: 0
  });
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    // Dispatch the fetchData action when the component mounts
    dispatch(longBunSkus());
  }, [openStatus, dispatch]);

  useEffect(() => {
    if (selectedEditRow.length > 0) {
      selectedEditRow.forEach((item) => {
        setLongBunData({
          sku: item.sku,
          quantity: Number(item.quantity)
        });
      });
    }
  }, [selectedEditRow]);

  /* handle long bun sku selection change */
  const handleLongBunSkuSelect = (e, value) => {
    const sku = value;
    const filteredSkuInfo = data.find((item) => item.sku === sku);
    setLongBunData((prevState) => ({
      ...filteredSkuInfo,
      quantity: prevState.quantity
    }));
  };
  /* handles quantity change */
  const handleQtyChange = (e) => {
    setLongBunData((prevState) => ({
      ...prevState,
      quantity: e.target.value
    }));
  };
  /* adds new long bun row data to state table array */
  const handleAddLongBunData = (e) => {
    if (longBunData.sku !== "" && longBunData.quantity !== 0) {
      if (scheduleData.find((sku) => sku.sku === longBunData.sku)) {
        setSnackBarStatus((prevState) => ({
          ...prevState,
          open: true,
          message: "Sku already exists",
          severity: "error"
        }));
      } else {
        setScheduleData((prevState) => [longBunData, ...prevState]);
        setLongBunDialog((prevState) => !prevState);
        setLongBunData({
          sku: "",
          quantity: 0
        });
      }
    }
  };
  /* cancels long bun row entry dialog */
  const handleCancelLongBunDataButton = (e) => {
    if (editTableRowStatus) {
      setEditTableRowStatus((prevState) => !prevState);
    }
    setLongBunDialog((prevState) => !prevState);
  };
  /* handles selected edit row */
  const handleEditTableRow = (e) => {
    updateObj(selectedEditRowIndex, longBunData);
    setLongBunDialog((prevState) => !prevState);
    setEditTableRowStatus((prevState) => !prevState);
    setLongBunData({
      sku: "",
      quantity: 0
    });
  };
  /* updated edited object in state array */
  const updateObj = (index, updatedObj) => {
    setScheduleData((prevItems) => {
      const updatedItems = [...prevItems];

      updatedItems[index] = { ...updatedItems[index], ...updatedObj };

      return updatedItems;
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Dialog
        data-testid="long-bun-dialog"
        open={openStatus}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="long-bun-dialog"
        PaperProps={{
          style: {
            maxWidth: "3000px ",
            width: "900px ",
            backgroundColor: "none",
            minHeight: "400px"
          }
        }}
      >
        <DialogContent id="long-bun-dialog-content">
          <Box display="flex" alignItems="center" gap={2}>
            <Typography className="dialog-text-long-bun-sku" component={"span"}>
              Long Bun Sku:
            </Typography>
            <Autocomplete
              disablePortal
              // id="long-bun-sku-select"
              className="law-tag-select"
              options={data.map((option) => option.sku)}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="SKU" />}
              onChange={handleLongBunSkuSelect}
            />
          </Box>

          <Typography className="dialog-text-quantity" component={"span"}>
            Quantity:
          </Typography>
          <Input
            type="number"
            inputProps={{ min: 0, max: 1000 }}
            value={longBunData.quantity}
            onChange={handleQtyChange}
            id="long-bun-number-input"
          />

          <Box mt={3} />
        </DialogContent>
        <DialogActions className="alert-button-div">
          <Button
            id="long-bun-cancel-dialog-button"
            className="alert-button"
            onClick={handleCancelLongBunDataButton}
            size="large"
          >
            cancel
          </Button>
          {editTableRowStatus ? (
            <Button
              id="long-bun-update-dialog-button"
              className="alert-button"
              onClick={handleEditTableRow}
            >
              Edit
            </Button>
          ) : (
            <Button
              id="long-bun-add-dialog-button"
              className="alert-button"
              onClick={handleAddLongBunData}
              size="large"
            >
              Add
            </Button>
          )}
        </DialogActions>
        <Divider />
        <DialogContent>
          {longBunData.sku ? (
            <>
              <Typography variant="h5" sx={{ lineHeight: 2 }}>
                <span style={{ fontWeight: "bold" }}>Sku:</span>&nbsp;
                {longBunData.sku}
              </Typography>
              <Typography variant="h5" sx={{ lineHeight: 2 }}>
                <span style={{ fontWeight: "bold" }}>
                  Long Bun Description:
                </span>
                &nbsp;
                {longBunData.description}
              </Typography>
              <Typography variant="h5" sx={{ lineHeight: 2 }}>
                <span style={{ fontWeight: "bold" }}>Cure Time:</span>&nbsp;
                {longBunData.cure_time_days}
              </Typography>
            </>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
