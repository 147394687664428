let azureUri;

if (process.env.REACT_APP_ENV === "production") {
  azureUri = "https://3zms.com";
} else if (process.env.REACT_APP_ENV === "development") {
  azureUri = "https://dev.3zms.com";
} else {
  azureUri = "http://localhost:3000";
}

const msalConfig = {
  auth: {
    clientId: "ac9ef6e9-b510-4be9-ab0a-1a0aaed63c55",
    authority:"https://login.microsoftonline.com/95b4977b-2e19-4499-8928-882caf67f448",
    redirectUri: azureUri
  },
  cache: {
    cacheLocation: "localStorage", //keeps login token across sites
    storeAuthStateInCookie: false //stores the auth state in your cache
  }
};

export default msalConfig;
