import { configureStore } from "@reduxjs/toolkit";

import foamReducer from "../Components/foamSchedule/foamPourSchedule/actions.js/longBunActions";
import scheduleReducer from "../redux-slice/scheduleSlice"
import authReducer from "../admin/actions/authActions.js";
import userReducer from "../admin/actions/userActions.js";
import slackReducer from "../redux-slice/slackSlice.jsx"

export const store = configureStore({
  reducer: {
    foam: foamReducer,
    auth: authReducer,
    user: userReducer,
    schedule: scheduleReducer,
    slack: slackReducer
  }
});
