import React from "react";
import { Grid } from "@mui/material";
const warehouse = require("../../media/images/3z_on_white_2.jpg");

const LandingPage = () => {
  return (
    <div className="Container">
      <Grid
        container
        component="main"
        sx={{ padding: "15px", justifyContent: "center" }}
      >
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            height: "50vh",
            width: "75%",
            backgroundImage: `url(${warehouse})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        />
      </Grid>
    </div>
  );
};

export default LandingPage;
