import React, { useState, useEffect, forwardRef } from "react";
import { useDispatch } from "react-redux";
import "./shortBunSchedule.css";
import { Box, Grid, Input, Typography, Snackbar, Button } from "@mui/material";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { ShortBunDialog } from "./shortbunDialog";
import {
  putShortBunSchedule,
  postShortBunSchedule,
  fetchShortBunByLongBunSerial,
  fetchT8Serial
} from "./action/shortBunAction";
import T8RemovalModal from "./T8RemovalModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faPrint,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ShortBunSchedule(props) {
  const dispatch = useDispatch();
  const [serialNumber, setSerialNumber] = useState("");
  const [serialT8Number, setT8SerialNumber] = useState("");
  const [availableCuts, setAvailableCuts] = useState(false);
  const [skuData, setSkuData] = useState({});
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [remove, setRemove] = useState(false);
  const [lastElementUsed, setLastElementUsed] = useState("t8_input_scan");

  useEffect(() => {
    document.getElementById(lastElementUsed).focus();
  }, [snackBarStatus, lastElementUsed]);

  // create short bun
  const submitShortBun = async (data) => {
    try {
      let newData = await dispatch(postShortBunSchedule(data)).unwrap();
      setSkuData(newData);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Schedule Submitted Successfully",
        severity: "success"
      }));
    } catch (error) {
      console.log("error", error);
      setSerialNumber("");

      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Schedule Submittal Error",
        severity: "error"
      }));
    }
  };

  // Update short bun
  const submitUpdateShortBun = async (data) => {
    try {
      let newData = await dispatch(putShortBunSchedule(data)).unwrap();
      setSkuData(newData);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Schedule Updated Successfully",
        severity: "success"
      }));
    } catch (error) {
      console.log("error", error);
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Short Bun Schedule Update Error",
        severity: "error"
      }));
    }
    putShortBunSchedule(data);
  };

  const fetchShortBunByLongBunSerialNumber = async (data) => {
    const fetchBySerial = await dispatch(
      fetchShortBunByLongBunSerial(data)
    ).unwrap();
    if (fetchBySerial.length > 0) {
      setSkuData(fetchBySerial);
      setAvailableCuts(true);
    } else {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Not a valid serial number",
        severity: "error"
      }));
      setSerialNumber("");
    }
  };

  const fetchT8SerialNumber = async (data) => {
    const fetchBySerial = await dispatch(fetchT8Serial(data)).unwrap();
    if (fetchBySerial.length >= 1) {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Sucessfull Reprint T8 ",
        severity: "success"
      }));
      setT8SerialNumber("");
    } else {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Not a valid serial number",
        severity: "error"
      }));
      setT8SerialNumber("");
    }
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const createShortBun = (shortBun) => {
    shortBun.schedule_date = moment().format("YYYY-MM-DD h:mm:ss");
    submitShortBun(shortBun);
  };

  const updateShortBun = (shortBun) => {
    shortBun.schedule_date = moment().format("YYYY-MM-DD h:mm:ss");
    submitUpdateShortBun(shortBun);
  };

  const handleSerialScan = (e) => {
    setSerialNumber(e.target.value);
  };

  const handleT8SerialScan = (e) => {
    setT8SerialNumber(e.target.value);
  };

  const handleRemoveT8 = () => {
    setRemove(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchShortBunByLongBunSerialNumber({ serial: e.target.value });
    }
  };
  const handleT8KeyDown = (e) => {
    if (e.key === "Enter") {
      fetchT8SerialNumber({ serial: e.target.value });
    }
  };

  return (
    <div className="Container">
      <Box ml={10}>
        <T8RemovalModal
          openRemoval={remove}
          closeRemoval={(close, snack) => {
            setRemove(close);
            if (snack === true) {
              setSnackBarStatus((prevState) => ({
                ...prevState,
                open: true,
                message: "T8 Cut Removed Successfully",
                severity: "success"
              }));
            }
            // else {
            //   setSnackBarStatus((prevState) => ({
            //     ...prevState,
            //     open: true,
            //     message: "Unable to remove T8 Cut",
            //     severity: "error"
            //   }));
            // }
          }}
        />
        <ShortBunDialog
          open={availableCuts}
          skuData={skuData}
          close={(closeModal) => {
            setAvailableCuts(closeModal);
            setSerialNumber("");
          }}
          addShortBunData={(shortBun) => {
            createShortBun(shortBun);
          }}
          updateShortBun={(shortBun) => {
            updateShortBun(shortBun);
          }}
          shortBunError={(message) => {
            setSnackBarStatus((prevState) => ({
              ...prevState,
              open: true,
              message: message,
              severity: "error"
            }));
          }}
        />
        <Snackbar
          open={snackBarStatus.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            className="alert-message"
            onClose={handleClose}
            severity={snackBarStatus.severity}
            sx={{ width: "100%" }}
          >
            {snackBarStatus.message}
          </Alert>
        </Snackbar>
        <Box>
          <h1 className="t8_title_header">T8 Processing</h1>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Box ml={5} mt={3} mb={8} className="short_bun_grid_box">
              <FontAwesomeIcon size="2xl" icon={faPlusCircle} />
              <Typography className="t8_header">Create Cuts</Typography>
              <Input
                id="t8_input_scan"
                name="t8_input_scan"
                className="t8_input_box"
                label="Serial Number"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => {
                  e.preventDefault();
                  setLastElementUsed(e.target.name);
                  handleSerialScan(e);
                }}
                value={serialNumber}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={3} className="short_bun_grid_box">
              <FontAwesomeIcon size="2xl" icon={faCircleMinus} />
              <Typography className="t8_header">Remove Cut</Typography>
              <Button
                id="serial_removal_button"
                onClick={(e) => handleRemoveT8()}
                className="t8_remove_button"
                size="large"
              >
                Remove
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <hr className="t8_divider" />
      <Box ml={10} mt={10}>
        <Grid container>
          <Grid item xs={6}>
            <Box ml={6} className="short_bun_grid_box">
              <FontAwesomeIcon size="2xl" icon={faPrint} />
              <Typography className="t8_header">Reprint Cut</Typography>
              <Input
                id="t8_reprint_input"
                name="t8_reprint_input"
                className="t8_input_box"
                label="Serial Number"
                onKeyDown={(e) => handleT8KeyDown(e)}
                onChange={(e) => {
                  e.preventDefault();
                  handleT8SerialScan(e);
                  setLastElementUsed(e.target.name);
                }}
                value={serialT8Number}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
