const truckTableColumns = [
  {
    name: "contact_name",
    label: "Customer",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "order_number",
    label: "Order Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "po_number",
    label: "PO Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "part_number",
    label: "Part Number (SKU)",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "ohm_part_number",
    label: "OHM Part Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "pickup_date",
    label: "Pickup Date",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "shipping_method",
    label: "Shipping Method",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  }
];

const truckSchedulingColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "contact_name",
    label: "Customer",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "order_number",
    label: "Order Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "po_number",
    label: "PO Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "part_number",
    label: "Part Number (SKU)",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },
  {
    name: "ohm_part_number",
    label: "OHM Part Number",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  },

  {
    name: "shipping_method",
    label: "Shipping Method",
    options: {
      filter: true,
      filterType: "multiselect",
      sort: true
    }
  }
];

const columns = { truckTableColumns, truckSchedulingColumns };

export default columns;
