import React, { useEffect } from "react";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import msalConfig from "./config/authConfig";

const container = document.getElementById("root");
const root = createRoot(container);
const TokenRenewalInterval = 600000; // 10 minutes

const AppWrapper = () => {
  const { instance } = useMsal();
  useEffect(() => {
    const obtainAccessToken = async () => {
      const tokenRequest = {
        scopes: ["api://ac9ef6e9-b510-4be9-ab0a-1a0aaed63c55/data.read"]
      };
      try {
        const response = await instance.acquireTokenSilent(tokenRequest);
        if (response) {
          localStorage.setItem("azureToken", response.accessToken);
          localStorage.setItem("username", response.account.username);
        }
      } catch (error) {
        console.error("Error obtaining access token:", error);
        // Log out the user or redirect to a logout page
        instance.logout();
      }
    };
    const tokenRenewalTimer = setInterval(() => {
      obtainAccessToken();
    }, TokenRenewalInterval);
    // Cleanup the timer on component unmount
    return () => clearInterval(tokenRenewalTimer);
  }, [instance]);

  return (
    <Provider store={store}>
      <App tab="home" />
    </Provider>
  );
};

export const msalInstance = new PublicClientApplication(msalConfig);

(async () => {
  await msalInstance.initialize();
  msalInstance.addEventCallback((e) => {
    if (e.eventType === EventType.LOGIN_SUCCESS) {
      localStorage.setItem(
        "roles",
        JSON.stringify(e.payload.idTokenClaims.roles)
      );
      msalInstance.setActiveAccount(e.payload.account);
    }
  });

  root.render(
    <MsalProvider instance={msalInstance}>
      <AppWrapper />
    </MsalProvider>
  );
})();
