import React, { useState, useEffect } from "react";
import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";
import Headerbar from "./Components/NavBar/Headerbar.jsx";
import Login from "./Components/loginPage/Login.jsx";
// MSL
import { msalInstance } from "./index.js";
// FOAM
import T8Reporting from "./Components/foamProduction/t8Reporting/T8Reporting.jsx";
import LandingPage from "./Components/LandingPage/LandingPage.jsx";
import FoamPourSchedule from "./Components/foamSchedule/foamPourSchedule/FoamPourSchedule.jsx";
import ShortBunSchedule from "./Components/foamSchedule/shortBunSchedule/shortBunSchedule.jsx";
import FoamPourProcessing from "./Components/foamProduction/foamPourProcessing/foamPourProcessing.jsx";

// D2C SCHEDULING
import D2CSchedule from "./Components/D2CSchedule/D2CSchedule.jsx";
import { ScheduleReport } from "./Components/ScheduleReport/ScheduleReport.jsx";
import TruckScheduleOpenOrdersV2 from "./Components/TruckSchedule/TruckScheduleOpenOrders_v2.jsx";
import TruckSchedulingV2 from "./Components/TruckSchedule/TruckScheduling_v2.jsx";
import SkuForm from "./Components/foamSku/SkuForm.jsx";

const SidebarLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Headerbar
        open={(open) => {
          setOpen(open);
        }}
      />
      <div className={`main_body ${open ? "open" : ""}`}>
        <Outlet />
      </div>
    </>
  );
};

function App() {
  const isAuthenticated = !!msalInstance.getActiveAccount();
  useEffect(() => {
    console.log(
      "schedule service url:",
      process.env.REACT_APP_SCHEDULE_SERVICE_BASE_URL
    );
    console.log(
      "foam schedule service url:",
      process.env.REACT_APP_FOAM_BASE_URL
    );
    console.log("isAuthenticated", isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={!isAuthenticated ? <Login /> : <SidebarLayout />}
          >
            <Route
              path="/foam-scheduling/long-bun-schedule"
              element={<FoamPourSchedule />}
            />
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/foam-scheduling/t8-processing"
              element={<ShortBunSchedule />}
            />
            <Route
              path="/foam-scheduling/long-bun-processing"
              element={<FoamPourProcessing />}
            />
            <Route
              path="/foam-scheduling/t8-reporting"
              element={<T8Reporting />}
            />
            <Route
              path="/foam-scheduling/foam-sku-form"
              element={<SkuForm />}
            />
            <Route
              path="/production-planning/d2c-schedule"
              element={<D2CSchedule auth={isAuthenticated} />}
            />
            <Route
              path="/production-planning/open-schedule-truck-orders_v2"
              element={<TruckScheduleOpenOrdersV2 auth={isAuthenticated} />}
            />
            <Route
              path="/production-planning/truck-scheduling_v2"
              element={<TruckSchedulingV2 auth={isAuthenticated} />}
            />
            <Route
              path="/production-planning/schedule-report"
              element={<ScheduleReport auth={isAuthenticated} />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
