import { msalInstance } from "../index";
export const getToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify authentication.");
  }

  const request = {
    scopes: ["api://ac9ef6e9-b510-4be9-ab0a-1a0aaed63c55/data.read"],
    account: account
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    console.error("Error acquiring token silently", error);
    try {
      const response = await msalInstance.acquireTokenPopup(request);
      return response.accessToken;
    } catch (popupError) {
      console.error("Error acquiring token with popup", popupError);
    }
  }
};
