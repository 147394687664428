import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FOAM_API from "../../../../utils/api";
import { getToken } from "../../../../utils/auth";

const initialState = {
  data: [],
  loading: false,
  error: null
};
export const fetchT8Report = createAsyncThunk(
  "foam/fetchT8report",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }

    const response = await fetch(
      FOAM_API +
        `/fetch-T8-report/${requestData.start_date}/${requestData.end_date}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData) // Make sure to pass the required data here
      }
    );
    const data = await response.json();
    return data;
  }
);

export const foamSlice = createSlice({
  name: "foam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetch t8
      .addCase(fetchT8Report.pending, (state) => {
        state.loading = true;
        state.updateLongBunError = null;
      })
      .addCase(fetchT8Report.fulfilled, (state, action) => {
        state.loading = false;
        state.updateLongBunData = action.payload;
      })
      .addCase(fetchT8Report.rejected, (state, action) => {
        state.loading = false;
        state.updateLongBunError = action.error.message;
      });
  }
});
