// Custom ToolBar Component for Open Orders Schedule Table which will populate when selecting rows
import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IndeterminateCheckBox, CompareArrows } from "@mui/icons-material";
import {
  unscheduleItems,
  unscheduleStockItems
} from "../../../redux-sliceRoutes/scheduleRoutes";
import { msalInstance } from "../../../index";

const MyCustomToolbarSelect = ({
  displayData,
  selectedRows,
  setSelectedRows,
  data,
  setCSVFileData,
  setSnackBarStatus
}) => {
  let dispatch = useDispatch();
  const handleClickInverseSelection = () => {
    const nextSelectedRows = displayData.reduce(
      (nextSelectedRows, _, index) => {
        if (
          !selectedRows.data.find((selectedRow) => selectedRow.index === index)
        ) {
          nextSelectedRows.push(index);
        }

        return nextSelectedRows;
      },
      []
    );

    setSelectedRows(nextSelectedRows);
  };

  const handleClickDeselectAll = () => {
    setSelectedRows([]);
  };

  // handle deletion of selected rows
  const handleDeleteItem = async () => {
    const selectedRowsData = selectedRows.data;
    const indexValues = selectedRowsData.map((selected) => selected.dataIndex);
    const itemsToDelete = indexValues.map((index) => data[index]);

    const stock_items = itemsToDelete
      .filter((item) => item.hasOwnProperty("schedule_order_item_id"))
      .map((item) => ({ id: item.id }))
      .filter((item) => item.id !== null);

    const non_stock_items = itemsToDelete
      .filter((item) => item.hasOwnProperty("order_number"))
      .map((item) => ({ id: item.id }))
      .filter((item) => item.id !== null);

    if (stock_items) {
      try {
        let userAccount = msalInstance.getActiveAccount();
        const response = await dispatch(
          unscheduleStockItems({
            user_email: userAccount.username,
            items: stock_items
          })
        );

        if (response.type === "unscheduleItems/fulfilled") {
          const itemsRemovedFromData = data.filter(
            (item, index) => !indexValues.includes(index)
          );
          setCSVFileData(itemsRemovedFromData);
          setSelectedRows([]);

          setSnackBarStatus({
            open: true,
            message: response.payload.message,
            severity: "success"
          });
        } else {
          throw new Error(
            response.error?.message || "An error occurred during unscheduling."
          );
        }
      } catch (error) {
        setSnackBarStatus({
          open: true,
          message: error.toString(),
          severity: "error"
        });
      }
    }

    if (non_stock_items) {
      try {
        let userAccount = msalInstance.getActiveAccount();
        const response = await dispatch(
          unscheduleItems({
            user_email: userAccount.username,
            items: non_stock_items
          })
        );

        if (response.type === "unscheduleItems/fulfilled") {
          const itemsRemovedFromData = data.filter(
            (item, index) => !indexValues.includes(index)
          );
          setCSVFileData(itemsRemovedFromData);
          setSelectedRows([]);

          setSnackBarStatus({
            open: true,
            message: response.payload.message,
            severity: "success"
          });
        } else {
          throw new Error(
            response.error?.message || "An error occurred during unscheduling."
          );
        }
      } catch (error) {
        setSnackBarStatus({
          open: true,
          message: error.toString(),
          severity: "error"
        });
      }
    }
  };

  return (
    <div>
      <Tooltip title={"Deselect ALL"}>
        <IconButton onClick={handleClickDeselectAll}>
          <IndeterminateCheckBox />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Inverse selection"}>
        <IconButton onClick={handleClickInverseSelection}>
          <CompareArrows />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Delete item(s)"}>
        <IconButton onClick={handleDeleteItem}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default MyCustomToolbarSelect;
