import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FOAM_API from "../../../../utils/api";
import { getToken } from "../../../../utils/auth";

const initialState = {
  data: [],
  loading: false,
  error: null
};

// Define the asynchronous thunk for fetching data
export const longBunSkus = createAsyncThunk(
  "foamShortBun/fetchData",
  async () => {
    const response = await fetch(FOAM_API + "/fetch-long-bun-skus");
    const data = await response.json();
    return data;
  }
);

export const fetchLongBunSerialByReadyDate = createAsyncThunk(
  "shortBun/fetchLongBunSerialByReadyDate",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(
      FOAM_API + "/fetch-long-bun-serial-by-ready-date",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData) // Make sure to pass the required data here
      }
    );
    const data = await response.json();
    return data;
  }
);

export const postShortBunSchedule = createAsyncThunk(
  "shortBun/postShortBunSchedule",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(FOAM_API + "/post-short-bun-schedule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);

export const putShortBunSchedule = createAsyncThunk(
  "shortBun/putShortBunSchedule",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(FOAM_API + "/update-short-bun-schedule", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);
export const fetchShortBunByLongBunSerial = createAsyncThunk(
  "shortBun/fetchShortBunByLongBunSerial",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(
      FOAM_API + "/fetch-short-bun-serial-by-long-bun-serial",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestData) // Make sure to pass the required data here
      }
    );
    const data = await response.json();
    return data;
  }
);

export const fetchT8Serial = createAsyncThunk(
  "shortBun/fetchT8Serial",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(FOAM_API + "/fetch-T8-serial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);

export const RemoveT8cut = createAsyncThunk(
  "shortBun/RemoveT8cut",
  async (requestData) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    const response = await fetch(FOAM_API + "/t8-serial-update", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);

export const shortBunFoam = createSlice({
  name: "foam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLongBunSerialByReadyDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLongBunSerialByReadyDate.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchLongBunSerialByReadyDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create short Bun Schedule
      .addCase(postShortBunSchedule.pending, (state) => {
        state.loading = true;
        state.postShortBunScheduleError = null;
      })
      .addCase(postShortBunSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.postShortBunSchedule = action.payload;
      })
      .addCase(postShortBunSchedule.rejected, (state, action) => {
        state.loading = false;
        state.postShortBunScheduleError = action.error.message;
      });
  }
});

// Action creators are generated for each case reducer function

export default shortBunFoam.reducer;
