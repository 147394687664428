import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  Grid,
  TextField,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import {
  submitTruckCSVSchedule,
  exportToOhm
} from "../../redux-sliceRoutes/truckScheduleRoutes";
import { msalInstance } from "../../index";
import "./TruckSchedule.css";

const TruckScheduleDialog = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [scheduleDate, setScheduleDate] = useState("");
  const [display, setDisplay] = useState([]);
  const [CSVFileData, setCSVFileData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.filterdData.displayData) {
      setCSVFileData(props.filterdData.scheduleData);
      setDisplay(props.filterdData.displayData);
    }
    if (scheduleDate !== "") {
      CSVFileData.forEach((item) => {
        item.pickup_date = scheduleDate;
      });
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [scheduleDate, props, CSVFileData]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: "18px",
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    props.closeDialog(false);
    setScheduleDate("");
  };

  const handleCancelClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    props.closeCancelDialog(false);
    setScheduleDate("");
  };

  const handleScheduleDateChange = (e) => {
    setScheduleDate(e.target.value);
  };

  const handleSaveSchedule = async (e) => {
    setDisabled(true);
    const missingOhmPartNumbers = props.filterdData.scheduleData.filter(
      (items) => !items.ohm_part_number
    );
    if (missingOhmPartNumbers.length > 0) {
      props.setSnackBarStatus({
        open: true,
        message:
          "An item selected is missing the OHM Part Number, Please fix it and resubmit.",
        severity: "error"
      });
      return;
    } else {
      let userAccount = msalInstance.getActiveAccount();
      props.setSnackBarStatus({
        open: true,
        message: "Scheduling items",
        severity: "info"
      });
      try {
        const response = await dispatch(
          submitTruckCSVSchedule({
            email: userAccount.username,
            csvFileData: CSVFileData,
            scheduled_date: scheduleDate
          })
        );

        if (response.type === "submitCSVSchedule/fulfilled") {
          props.setSnackBarStatus({
            open: true,
            message: response.payload.message,
            severity: "info"
          });
          requestExportToOhm(scheduleDate);
        } else {
          console.log("response.error.message", response.payload.message);
          props.setSnackBarStatus({
            open: true,
            message: response.payload.message,
            severity: "error"
          });
        }
      } catch (error) {
        props.setSnackBarStatus({
          open: true,
          message: error.message,
          severity: "error"
        });
      } finally {
        setDisabled(true);
        setScheduleDate("");
        props.closeDialog();
        setScheduleDate("");
      }
    }
  };

  const requestExportToOhm = async (date) => {
    await dispatch(exportToOhm({ scheduled_date: date })).then((response) => {
      if (response.type === "exportToOhm/fulfilled") {
        props.setSnackBarStatus({
          open: true,
          message: response.payload.message,
          severity: "info"
        });
      }
    });
  };

  return (
    <Dialog
      open={props.openDialog}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      PaperProps={{
        className: "dialog_paper"
      }}
    >
      <DialogContent id="long-bun-dialog-content">
        <Grid container>
          <Grid item xs={7}>
            <Typography
              className="dialog_title"
              variant="h5"
              component={"span"}
            >
              Pickup Date:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="truck_scheduling_date_picker"
              type="date"
              label="PICKUP DATE"
              name="date"
              onChange={handleScheduleDateChange}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                min: moment().format("YYYY-MM-DD")
              }}
            />
          </Grid>
        </Grid>
        <Box mt={6} mb={6}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="large">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Customer Name</StyledTableCell>
                      <StyledTableCell>Order Number</StyledTableCell>
                      <StyledTableCell>Item (qty)</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {display.map((displayData, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <StyledTableCell
                          className="t8_production_dialog_table_cell"
                          component="th"
                          align="left"
                        >
                          <Typography className="t8_production_dialog_table_text">
                            {displayData.customer_name}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Typography className="t8_production_dialog_table_text">
                            {displayData.order_number}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" align="center">
                          <Typography className="t8_production_dialog_table_text">
                            {displayData.count}
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Box>
        <DialogActions>
          <Grid container>
            <Grid item xs={6}>
              <Button
                id="cancel_close_dialog"
                onClick={handleCancelClose}
                className="cancel_button"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button
                id="save_schedule_dialog"
                className="save_schedule_dialog"
                disabled={disabled}
                onClick={handleSaveSchedule}
              >
                Submit Schedule
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TruckScheduleDialog;
