import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const { AUTH_API_ROOT } = require("../../utils/api");

const userToken = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const initialState = {
  data: [],
  loading: false,
  userInfo: null,
  userToken,
  error: null
};

export const fetchUsers = createAsyncThunk("users", async () => {
  const response = await fetch(AUTH_API_ROOT + "/users", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    }
  });
  const data = await response.json();
  return data;
});

export const fetchUsersById = createAsyncThunk(
  "users/fetchUsersById",
  async (data) => {
    const response = await fetch(AUTH_API_ROOT + `/user/${data.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      }
    });
    const responseData = await response.json();
    return responseData;
  }
);

/* Request that that creates new user */
export const createNewUser = createAsyncThunk(
  "users/createNewUser",
  async (requestData) => {
    const response = await fetch(`${AUTH_API_ROOT}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      options: {
        mode: "cors"
      },
      body: JSON.stringify(requestData) // Make sure to pass the required data here
    });
    const data = await response.json();
    return data;
  }
);

/* Request that that updates user */
export const updateUser = createAsyncThunk("users/updateUser", async (user) => {
  const response = await fetch(`${AUTH_API_ROOT}/user/${user.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    options: {
      mode: "cors"
    },
    body: JSON.stringify(user) // Make sure to pass the required data here
  });
  const data = await response.json();
  return data;
});

/* Retrieves schedule for specified date with component type. */
export const createEmployeeCredentials = createAsyncThunk(
  "users/createEmployeeCredentials",
  async (requestData) => {
    const response = await fetch(
      `${AUTH_API_ROOT}/create-employee-credentials`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        options: {
          mode: "cors"
        },
        body: JSON.stringify(requestData) // Make sure to pass the required data here
      }
    );
    const data = await response.json();
    return data;
  }
);

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear(); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    }
  },

  extraReducers: (builder) => {
    builder
      //fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.createUser = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.createUser = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.createUser = action.error.message;
      })
      //Fetch User By ID
      .addCase(fetchUsersById.pending, (state) => {
        state.loading = true;
        state.fetchUsersById = null;
      })
      .addCase(fetchUsersById.fulfilled, (state, action) => {
        state.loading = false;
        state.fetchUsersById = action.payload;
      })
      .addCase(fetchUsersById.rejected, (state, action) => {
        state.loading = false;
        state.fetchUsersById = action.error.message;
      })
      // Create new user
      .addCase(createNewUser.pending, (state) => {
        state.loading = true;
        state.createNewUser = null;
      })
      .addCase(createNewUser.fulfilled, (state, action) => {
        state.loading = false;
        state.createNewUser = action.payload;
      })
      .addCase(createNewUser.rejected, (state, action) => {
        state.loading = false;
        state.createNewUser = action.error.message;
      })
      //Update user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.updateUser = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.updateUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.updateUser = action.error.message;
      })
      // Create employee creds
      .addCase(createEmployeeCredentials.pending, (state) => {
        state.loading = true;
        state.createEmployeeCredentials = null;
      })
      .addCase(createEmployeeCredentials.fulfilled, (state, action) => {
        state.loading = false;
        state.createEmployeeCredentials = action.payload;
      })
      .addCase(createEmployeeCredentials.rejected, (state, action) => {
        state.loading = false;
        state.createEmployeeCredentials = action.error.message;
      });
  }
});

// Action creators are generated for each case reducer function
export const { logout } = userSlice.actions;
export default userSlice.reducer;
