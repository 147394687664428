import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  IconButton,
  Grid,
  Typography,
  TextField,
  Snackbar,
  Button
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { fetchT8Report } from "./actions/t8Actions";
import { useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import T8ReportDialog from "./T8ReportDialog";
import MuiAlert from "@mui/material/Alert";
import "./T8Reporting.css";
import { Parser } from "@json2csv/plainjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell>{expandComponent}</TableCell>
        </TableRow>
      )}
    </>
  );
};

const T8Reporting = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().subtract(15, "days"));
  const [endDate, setEndDate] = useState(dayjs().add(15, "days"));
  const [serialScan, setSerialScan] = useState("");
  const [T8DialogData, setT8DialogData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });

  useEffect(() => {
    const fetchT8Reports = async (data) => {
      let t8Data = await dispatch(fetchT8Report(data)).unwrap();
      t8Data.forEach((newData) => {
        let shortBun = [];
        newData.short_bun_schedule_by_short_bun_id &&
          newData.short_bun_schedule_by_short_bun_id.forEach((short_bun) => {
            let shortBunBdft =
              (short_bun.foam_sku[0].length_in *
                short_bun.foam_sku[0].width_in *
                newData.long_bun_height) /
              144;
            shortBun.push(short_bun.quantity * shortBunBdft);
          });
        var sum = shortBun.reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0);
        newData.cutTotal = sum;
      });
      setRows(t8Data);
      setRowData(t8Data);
    };

    let start_date = moment(startDate)
      .utc()
      .subtract(15, "days")
      .format("YYYY-MM-DD");
    let end_date = moment(endDate).utc().add(15, "days").format("YYYY-MM-DD");
    fetchT8Reports({ start_date: start_date, end_date: end_date });
    document.getElementById("t8-reporting-text-field").focus();
  }, [endDate, startDate, dispatch]);

  // Export to CSV button function PS-595
  const exportToCSV = () => {
    const fields = [
      { label: "Serial Number", value: "serial_number" },
      {
        label: "SKU",
        value: (row) => row.foam_pour_schedule[0].foam_sku[0].sku
      },
      {
        label: "Pour Date",
        value: (row) => moment(row.pour_date).format("MM-DD-YYYY")
      },
      {
        label: "Total Qty (bdft)",
        value: (row) =>
          row.foam_scan_log[0] ? row.foam_scan_log[0].bdft_qty : ""
      },
      { label: "Cut Qty (bdft)", value: "cutTotal" },
      {
        label: "Waste Qty (bdft)",
        value: (row) => {
          if (row.foam_scan_log[0]) {
            const wasteQty = row.foam_scan_log[0].bdft_qty - row.cutTotal;
            return wasteQty.toFixed(3);
          }
          return "";
        }
      },
      {
        label: "Yield",
        value: (row) =>
          row.foam_scan_log[0]
            ? Number(
                row.cutTotal / row.foam_scan_log[0].bdft_qty
              ).toLocaleString(undefined, {
                style: "percent",
                minimumFractionDigits: 2
              })
            : ""
      }
    ];

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(rows);

    // format todays datetime for file
    const today = moment().format("YYYY-MM-DD_HH-mm-ss");
    const filename = `t8_report_${today}.csv`;

    // Download the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSerialScan = (e) => {
    setSerialScan(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      let T8Data = rowData.find((row) => row.serial_number === e.target.value);
      if (T8Data) {
        setT8DialogData([T8Data]);
        setOpenDialog(true);
      } else {
        setSnackBarStatus((prevState) => ({
          ...prevState,
          open: true,
          message: "No T8 Data Avaliable",
          severity: "error"
        }));
        setSerialScan("");
      }
    }
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const focusOnSerialNumberInput = () => {
    document.getElementById("t8-reporting-text-field").focus();
  };

  return (
    <div className="Container">
      <T8ReportDialog
        open={openDialog}
        T8Data={T8DialogData}
        close={(closeModal) => {
          setT8DialogData(closeModal);
          setOpenDialog(false);
          setSerialScan("");
          focusOnSerialNumberInput();
        }}
      />
      <Box ml={4} pb={10} mr={6}>
        <Snackbar
          open={snackBarStatus.open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <Alert
            className="alert-message"
            onClose={handleClose}
            severity={snackBarStatus.severity}
            sx={{ width: "100%" }}
          >
            {snackBarStatus.message}
          </Alert>
        </Snackbar>
        <Grid container justifyContent="space-between">
          <h1 className="schedule-header">T8 Production Report</h1>
          <Grid item sx={{ mt: 5 }}>
            <Button
              variant="contained"
              className="t8_button"
              color="primary"
              onClick={exportToCSV}
            >
              Export to CSV
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Grid container>
              <Grid className="t8_reporting_date_grid">
                <Typography variant="h4">Search Serial Number</Typography>
                <TextField
                  id="t8-reporting-text-field"
                  style={{ textAlign: "right" }}
                  className="t8_reporting_input_containers"
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => {
                    e.preventDefault();
                    handleSerialScan(e, rows);
                  }}
                  value={serialScan}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item className="t8_reporting_date_grid">
                  <Typography variant="h4">Start Date</Typography>
                  <DatePicker
                    value={startDate}
                    className="t8_reporting_input_containers"
                    onChange={(newValue) => {
                      setStartDate(newValue.$d);
                      if (newValue.$d > endDate) {
                        setEndDate(dayjs(newValue.$d).add(1, "day"));
                      }
                    }}
                  />
                </Grid>
                <Grid item className="t8_reporting_date_grid">
                  <Typography variant="h4">End Date</Typography>
                  <DatePicker
                    value={endDate}
                    className="t8_reporting_input_containers"
                    onChange={(newValue) => {
                      setEndDate(newValue.$d);
                      if (newValue.$d < endDate) {
                        setStartDate(dayjs(newValue.$d).subtract(1, "day"));
                      }
                    }}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Long Bun Serial Number </StyledTableCell>
                  <StyledTableCell>SKU </StyledTableCell>
                  <StyledTableCell>Long Bun Pour Date</StyledTableCell>
                  <StyledTableCell>Long Bun Total Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Long Bun Cut Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Long Bun Waste Qty (bdft)</StyledTableCell>
                  <StyledTableCell>Yield :</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <ExpandableTableRow
                    key={row.id}
                    expandComponent={
                      <Table>
                        <TableHead>
                          <TableRow key={row.id}>
                            <StyledTableCell>Short Bun SKU</StyledTableCell>
                            <StyledTableCell align="right">
                              Quantity
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Short Bun Cut Date
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.short_bun_schedule_by_short_bun_id.map(
                            (shortBun) => (
                              <TableRow key={shortBun.id}>
                                <StyledTableCell
                                  className="t8_production_dialog_table_cell"
                                  component="th"
                                  align="left"
                                >
                                  {shortBun.foam_sku[0].sku}
                                </StyledTableCell>
                                <StyledTableCell component="th" align="center">
                                  {shortBun.quantity}
                                </StyledTableCell>
                                <StyledTableCell
                                  className="t8_production_dialog_table_cell"
                                  component="th"
                                  align="right"
                                >
                                  {moment(row.foam_scan_log[0].date).format(
                                    "MM-DD-YYYY"
                                  )}
                                </StyledTableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    }
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.serial_number}
                    </StyledTableCell>
                    <StyledTableCell style={{ minWidth: "150px" }}>
                      {row.foam_pour_schedule[0].foam_sku[0].sku}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.pour_date).format("MM-DD-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.foam_scan_log[0] && row.foam_scan_log[0].bdft_qty}
                    </StyledTableCell>
                    <StyledTableCell>{row.cutTotal.toFixed(3)}</StyledTableCell>
                    <StyledTableCell
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      {row.foam_scan_log[0] &&
                        (
                          row.foam_scan_log[0].bdft_qty -
                          row.cutTotal.toFixed(3)
                        ).toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Number(
                        row.cutTotal / row.foam_scan_log[0].bdft_qty
                      ).toLocaleString(undefined, {
                        style: "percent",
                        minimumFractionDigits: 2
                      })}
                    </StyledTableCell>
                  </ExpandableTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};

export default T8Reporting;

// Long Bun Serial Number	foam_serial_number.serial_number
// Long Bun Pour Date	foam_serial_number.pour_date
// Long Bun Total Qty (bdft)	foam_scan_log.bdft_qty
// Long Bun Cut Qty (bdft)	foam_scan_log.cut_bdft_qty
// Long Bun Waste Qty (bdft)	foam_scan_log.cut_bdft_qty
// Short Bun SKU (expand)	foam_sku.sku Joined to foam_short_bun_schedule where long_bun_serial_nunber_id = foam_serial_number.serial_number
// Short Bun SKU (expand)	foam_scan_log.date for processed short bun
