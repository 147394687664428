import { createSlice } from "@reduxjs/toolkit";
import { slackMessage } from "../redux-sliceRoutes/slackRoutes";

export const slackSlice = createSlice({
  name: "slackSlice",
  initialState: {
    loading: false,
    slackStatus: "",
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(slackMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(slackMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.slackStatus = action.payload;
      state.error = "";
    });
    builder.addCase(slackMessage.rejected, (state, action) => {
      state.loading = false;
      state.slackStatus = "";
      state.error = action.error.message;
    });
    
  },
});

export default slackSlice.reducer;