import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Input,
  Dialog,
  DialogActions,
  Typography,
  DialogContent,
  Grid,
  Button,
  Snackbar,
  Alert
} from "@mui/material";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import { useDispatch } from "react-redux";
import { RemoveT8cut } from "./action/shortBunAction";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";

const T8RemovalModal = (props) => {
  const dispatch = useDispatch();
  const [serialNumber, setSerialNumber] = useState("");
  const [removedSerialNumber, setRemovedSerialNumber] = useState([]);
  const [firstColumn, setFirstColumn] = useState([]);
  const [secondColumn, setSecondColumn] = useState([]);
  const [openSnack, setOpen] = useState(false);

  useEffect(() => {
    const midIndex = Math.ceil(removedSerialNumber.length / 2);
    setFirstColumn(removedSerialNumber.slice(0, midIndex));
    setSecondColumn(removedSerialNumber.slice(midIndex));
  }, [removedSerialNumber]);

  const handleSerialScan = (e) => {
    setSerialNumber(e.target.value);
    // Access the current debounce function through the ref
    debounceFuncRef.current(e.target.value);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    props.closeRemoval(false);
    setSerialNumber("");
    setRemovedSerialNumber([]);
    setOpen(false);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // useRef to store the debounce function
  const debounceFuncRef = useRef(
    debounce((value) => {
      removeT8cuts({ serial: value });
    }, 200)
  );

  const removeT8cuts = async (data) => {
    try {
      const actionResult = await dispatch(RemoveT8cut(data)).unwrap();
      if (actionResult.length >= 1) {
        setRemovedSerialNumber((prevSerials) => [
          ...prevSerials,
          actionResult[0]
        ]);
        setSerialNumber("");
      } else {
        setOpen(true);
        setSerialNumber("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={props.openRemoval}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      PaperProps={{
        style: {
          minWidth: "650px ",
          backgroundColor: "none",
          padding: 24,
          paddingBottom: 25
        }
      }}
    >
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleSnackClose}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "24px" }}
        >
          Serial Number is Invalid or No Longer Exists
        </Alert>
      </Snackbar>

      <DialogContent id="long-bun-dialog-content">
        <Typography
          className="dialog_titless"
          variant="h4"
          component={"span"}
          style={{ textDecoration: "underline", fontWeight: "bold" }}
        >
          T8 Serial Number Deletion Tool
        </Typography>
      </DialogContent>
      <Box
        sx={{
          flexShrink: 1,
          paddingLeft: " 24px",
          marginTop: "10px"
        }}
      >
        <Grid justifyContent={"center"}>
          <Grid item xs={8}>
            <FontAwesomeIcon size="2xl" icon={faCircleMinus} />
            <Input
              id="remove-t8-dialog"
              autoFocus
              className="datePicker"
              style={{ width: "80%" }}
              label="Serial Number"
              onChange={(e) => handleSerialScan(e)}
              value={serialNumber}
              name="Scan Serial Number"
            />
          </Grid>

          <Box mt={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>
                  Deleted Serial Numbers:
                </Typography>
              </Grid>
              <List marker="disc" sx={{ fontSize: "25px" }}>
                <Grid item xs={6}>
                  {firstColumn.length > 0 ? (
                    firstColumn.map((serial, index) => (
                      <ListItem sx={{ marginLeft: -3 }}>
                        <Typography
                          key={index}
                          variant="h5"
                          sx={{
                            textDecoration: "underline",
                            lineHeight: 1.5,
                            marginBottom: 1
                          }}
                        >
                          {serial?.serial_number || "No serial number"}
                        </Typography>
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="h6">
                      No serial numbers removed
                    </Typography>
                  )}
                </Grid>
              </List>
              <List marker="disc" sx={{ fontSize: "25px" }}>
                <Grid item xs={6}>
                  {secondColumn.length > 0
                    ? secondColumn.map((serial, index) => (
                        <ListItem sx={{ marginLeft: -3 }}>
                          <Typography
                            key={index}
                            variant="h5"
                            sx={{
                              textDecoration: "underline",
                              lineHeight: 1.5,
                              marginBottom: 1
                            }}
                          >
                            {serial?.serial_number || "No serial number"}
                          </Typography>
                        </ListItem>
                      ))
                    : ""}
                </Grid>
              </List>
            </Grid>
          </Box>
        </Grid>
        <DialogActions>
          <Button className="addBunLineButton" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default T8RemovalModal;
