import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import SearchIcon from "@mui/icons-material/Search";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { IconButton, Typography, ListItem } from "@mui/material";
import { msalInstance } from "../..";
import { useMediaQuery } from "react-responsive";
import "./MenuBar.css";
import ListAltIcon from "@mui/icons-material/ListAlt";

export default function MenuBar(props) {
  const account = msalInstance.getActiveAccount();
  const theme = useTheme();
  const [openProductionPlanningMenu, setOpenProductionPlanningMenu] =
    useState(false);
  const [foamProductionComponent, setFoamProductionComponent] =
    React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const navigate = useNavigate();
  // Mobile responsive boolean
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  // const isTabletOrMobile = useMediaQuery({ maxDeviceWidth: 1224 });

  const handleFoamProductionClick = () => {
    setFoamProductionComponent(!foamProductionComponent);
  };

  const handleProductionPlanningClick = () => {
    setOpenProductionPlanningMenu(!openProductionPlanningMenu);
  };

  const handleNavigate = (route) => {
    setSelectedItem(route);
    navigate(route);
  };

  const handleDrawerClose = () => {
    props.close(false);
  };

  const THEME = createTheme({
    typography: {
      fontFamily: ["Montserrat"]
    }
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }));

  const MenuItem = ({
    icon,
    text,
    onClick,
    selected,
    hasSubMenu,
    state,
    rolesAllowed
  }) => {
    // Function to check if the user has the required role
    const hasRequiredRole = () => {
      const userRoles = localStorage.getItem("roles");
      if (!userRoles) {
        return false; // User roles not found, deny accessj
      }
      // const userRolesArray = JSON.parse(userRoles);
      const userRolesArray = ["admin"];
      // Check if any of the user's roles match the required roles
      const allowed = rolesAllowed.some((role) =>
        userRolesArray.includes(role)
      );
      return allowed;
    };

    // Render the menu item only if the user has the required role
    return hasRequiredRole() ? (
      <ListItemButton onClick={onClick} selected={selected}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {hasSubMenu && (state ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
    ) : null;
  };

  const SubMenu = ({ items, handleClick, open }) => (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List className="submenu-item" component="div" disablePadding>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            icon={item.icon}
            text={item.text}
            onClick={() => handleClick(item.route)}
            selected={item.selected}
            hasSubMenu={item.hasSubMenu}
            state={item.state}
            rolesAllowed={item.rolesAllowed} // Pass the rolesAllowed prop to MenuItem
          />
        ))}
      </List>
    </Collapse>
  );

  const UserCard = ({ account }) => (
    <List className="userCard">
      <Typography mt={-1.4} className="user-name">
        {account.name}
      </Typography>
    </List>
  );

  return (
    <ThemeProvider theme={THEME}>
      <Box sx={{ display: "flex", width: "20%" }}>
        <Drawer
          sx={{
            maxWidth: isDesktopOrLaptop ? "20%" : "70%",
            display: "flex",
            "& .MuiDrawer-paper": {
              minWidth: isDesktopOrLaptop ? "20%" : "20%",
              boxSizing: "border-box",
              background: "#1F2C32",
              color: "white",
              display: "flex"
            }
          }}
          variant="persistent"
          anchor="left"
          open={props.open}
        >
          <DrawerHeader className="drawer-header">
            <Box
              className="LogoContainer"
              sx={{ mr: 2, ...(!props.open && { display: "none" }) }}
            >
              <Typography className="header-font">3ZMS</Typography>
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <Box>
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    color="white"
                    size="xl"
                    style={{
                      fontWeight: "bold",
                      margin: "20px",
                      fontSize: "45px"
                    }}
                  />
                </Box>
              ) : (
                <FontAwesomeIcon icon={faChevronRight} color="white" />
              )}
            </IconButton>
          </DrawerHeader>

          <List>
            <MenuItem
              icon={
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #F101C0 4%, #6100FF 100%)"
                  }}
                >
                  <Box ml={-0.3}>PP</Box>
                </div>
              }
              text="Production Planning"
              onClick={handleProductionPlanningClick}
              hasSubMenu={true} // Pass true for the parent menu
              state={openProductionPlanningMenu}
              rolesAllowed={["admin", "schedule"]}
            />

            <SubMenu
              items={[
                {
                  icon: <CalendarMonthIcon sx={{ color: "white" }} />,
                  text: "D2C Schedule",
                  route: "/production-planning/d2c-schedule",
                  selected:
                    selectedItem === "/production-planning/d2c-schedule",
                  rolesAllowed: ["admin", "schedule"]
                },
                {
                  icon: <CalendarMonthIcon sx={{ color: "white" }} />,
                  text: "Open Scheduled Truck Orders",
                  route: "/production-planning/open-schedule-truck-orders_v2",
                  selected:
                    selectedItem ===
                    "/production-planning/open-schedule-truck-orders_v2",
                  rolesAllowed: ["admin", "schedule"]
                },
                {
                  icon: <CalendarMonthIcon sx={{ color: "white" }} />,
                  text: "Truck Scheduling",
                  route: "/production-planning/truck-scheduling_v2",
                  selected:
                    selectedItem === "/production-planning/truck-scheduling_v2",
                  rolesAllowed: ["admin", "schedule"]
                }
                // {
                //   icon: <SearchIcon sx={{ color: "white" }} />,
                //   text: "Schedule Order Report",
                //   route: "/production-planning/schedule-report",
                //   selected:
                //     selectedItem === "/production-planning/schedule-report",
                //   rolesAllowed: ["admin", "schedule"]
                // }
              ]}
              handleClick={handleNavigate}
              open={openProductionPlanningMenu}
            />
            <MenuItem
              icon={
                <div
                  className="numberCircle"
                  style={{
                    background: "linear-gradient( #16a1f4 4%, #d28c19 100%)"
                  }}
                >
                  <Box ml={-0.3}>FP</Box>
                </div>
              }
              text="Foam Production"
              onClick={handleFoamProductionClick}
              hasSubMenu={true} // Pass true for the parent menu
              state={foamProductionComponent}
              rolesAllowed={["admin", "foam"]}
            />
            <SubMenu
              items={[
                {
                  icon: <CalendarMonthIcon sx={{ color: "white" }} />,
                  text: "Foam Pour Schedule",
                  route: "/foam-scheduling/long-bun-schedule",
                  selected:
                    selectedItem === "/foam-scheduling/long-bun-schedule",
                  rolesAllowed: ["admin", "foam"]
                },
                {
                  icon: <LibraryAddCheckIcon sx={{ color: "white" }} />,
                  text: "Foam Pour Processing",
                  route: "/foam-scheduling/long-bun-processing",
                  selected:
                    selectedItem === "/foam-scheduling/long-bun-processing",
                  rolesAllowed: ["admin", "foam"]
                },
                {
                  icon: <LibraryAddCheckIcon sx={{ color: "white" }} />,
                  text: "T8 Processing",
                  route: "/foam-scheduling/t8-processing",
                  selected: selectedItem === "/foam-scheduling/t8-processing",
                  rolesAllowed: ["admin", "foam"]
                },
                {
                  icon: <InboxIcon sx={{ color: "white" }} />,
                  text: "T8 Production Report",
                  route: "/foam-scheduling/t8-reporting",
                  selected: selectedItem === "/foam-scheduling/t8-reporting",
                  rolesAllowed: ["admin", "foam"]
                },
                {
                  icon: <ListAltIcon sx={{ color: "white" }} />,
                  text: "Foam Sku Form",
                  route: "/foam-scheduling/foam-sku-form",
                  selected: selectedItem === "/foam-scheduling/t8-foam-sku",
                  rolesAllowed: ["admin", "foam"]
                }
              ]}
              handleClick={handleNavigate}
              open={foamProductionComponent}
            />
          </List>
          <List style={{ marginTop: `auto` }}>
            <ListItem id="User">
              {account ? <UserCard account={account} /> : null}
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
