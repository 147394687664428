// Columns for the open orders MUI data table
const scheduleTableColumns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "part_number",
    label: "Part Number (SKU)",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "ohm_part_number",
    label: "OHM Part Number",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "qty",
    label: "Qty",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "batch",
    label: "Priority",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "order_number",
    label: "Order Number",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "po_number",
    label: "PO Number",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "order_date",
    label: "Order Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "order_item_type",
    label: "Stock Type",
    options: {
      filter: false,
      sort: true
    }
  }
];

export default scheduleTableColumns;
