import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "../../../utils/auth";
import FOAM_API from "../../../utils/api";

// Thunk for submitting the SKU form
export const submitSkuForm = createAsyncThunk(
  "skuForm/submit",
  async ({ foamGrade, longBunWidth }, { rejectWithValue }) => {
    // Prepare the form data to send to the backend
    const formData = {
      foamGrade,
      longBunWidth
    };
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }

    try {
      const response = await fetch(FOAM_API + "/foam-sku-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      // Check if the response is okay
      if (!response.ok) {
        throw new Error("Failed to submit SKU form");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("submitSkuForm error:", error);

      const errorPayload = {
        message: error.response?.data?.detail || "An unexpected error occurred",
        status: error.response?.status || 500
      };

      console.log("Error payload:", errorPayload);
      return rejectWithValue(errorPayload);
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null
};

export const foamSku = createSlice({
  name: "foam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitSkuForm.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitSkuForm.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(submitSkuForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default foamSku.reducer;
