// Columns for the open orders MUI data table
const ScheduleReportTableColumns = [
  {
    name: "order_number",
    label: "Order Number",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "po_number",
    label: "PO Number",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "part_number",
    label: "Part Number (SKU)",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "ohm_part_number",
    label: "OHM Part",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "order_item_type",
    label: "Order Item Type",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "shipping_method",
    label: "Shipping Method",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "scheduled_at",
    label: "Schedule Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "scheduled_by",
    label: "Scheduled By",
    options: {
      filter: false,
      sort: true
    }
  },
];

export default ScheduleReportTableColumns;
