import { createSlice } from "@reduxjs/toolkit";
import {
  getSchedule,
  emailCSVSchedule,
  unscheduleItems,
  exportToOhm,
  getScheduleOrderItemsReport
} from "../redux-sliceRoutes/scheduleRoutes";

export const scheduleSlice = createSlice({
  // name the slice
  name: "scheduleSlice",
  //create your initial state
  initialState: {
    loading: false,
    list: [],
    reportData: null,
    unscheduleItems: [],
    email_message: "",
    unscheduleItemsMessage: "",
    error: ""
  },
  // pending, fulfilled and rejected responses
  extraReducers: (builder) => {
    // Get schedule report
    builder.addCase(getScheduleOrderItemsReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getScheduleOrderItemsReport.fulfilled, (state, action) => {
      state.loading = false;
      state.reportData = action.payload;
      state.error = "";
    });
    builder.addCase(getScheduleOrderItemsReport.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message;
    });
    // get schedule
    builder.addCase(getSchedule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
      state.error = "";
    });
    builder.addCase(getSchedule.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message;
    });
    // email CSV Schedule
    builder.addCase(emailCSVSchedule.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(emailCSVSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.email_message = action.payload.message;
      state.error = "";
    });
    builder.addCase(emailCSVSchedule.rejected, (state, action) => {
      state.loading = false;
      state.email_message = "";
      state.error = action.error.message;
    });
    //unshcedule items
    builder.addCase(unscheduleItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unscheduleItems.fulfilled, (state, action) => {
      state.loading = false;
      state.unscheduleItemsMessage = action.payload.message;
      state.error = "";
    });
    builder.addCase(unscheduleItems.rejected, (state, action) => {
      state.loading = false;
      state.email_message = "";
      state.error = action.error.message;

      //export to ohm
      builder.addCase(exportToOhm.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(exportToOhm.fulfilled, (state, action) => {
        state.loading = false;
        state.unscheduleItemsMessage = action.payload.message;
        state.error = "";
      });
      builder.addCase(exportToOhm.rejected, (state, action) => {
        state.loading = false;
        state.email_message = "";
        state.error = action.error.message;
      });
    });
  }
});

export default scheduleSlice.reducer;
