import React, { useState, forwardRef } from "react";
import { Snackbar, Button, Grid, Box, Typography, Input } from "@mui/material";
import { useDispatch } from "react-redux";
import { submitSkuForm } from "./actions/foamSkuActions";
import "./skuForm.css";
import MuiAlert from "@mui/material/Alert";
import FoamSkuDialog from "./FoamSkuDialog";

const SkuForm = () => {
  const dispatch = useDispatch();
  const [foamGrade, setFoamGrade] = useState("");
  const [longBunWidth, setLongBunWidth] = useState("");
  const [foamSku, setFoamSku] = useState({});
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      foamGrade,
      longBunWidth
    };

    const sku = await dispatch(submitSkuForm(formData)).unwrap();
    if (sku.length) {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Sucessfully create foam sku",
        severity: "success"
      }));
      setFoamSku(sku);
      setOpenDialog(true);
    } else {
      setSnackBarStatus((prevState) => ({
        ...prevState,
        open: true,
        message: "Foam SKU already exist",
        severity: "info"
      }));
    }
  };

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div className="Container">
      <FoamSkuDialog
        open={openDialog}
        skuData={foamSku}
        close={(closeModal) => {
          setFoamGrade("");
          setLongBunWidth("");
          setOpenDialog(false);
        }}
      />
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item xs={9}>
          <Box ml={10}>
            <h1 className="sku-form-header">Foam SKU Builder</h1>
            <Box ml={5}>
              <Grid>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        className="foam-processing-date-header"
                        style={{
                          marginRight: "8px",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Foam Grade:
                      </Typography>
                      <Input
                        className="sku-form-textfield"
                        label="Foam Grade"
                        variant="outlined"
                        value={foamGrade}
                        onChange={(e) => setFoamGrade(e.target.value)}
                        required
                        style={{ flexGrow: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <Box p={1} />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Typography
                        className="foam-processing-date-header"
                        style={{
                          marginRight: "8px"
                        }}
                      >
                        Width(in):
                      </Typography>
                      <Input
                        label="Long Bun Width"
                        type="number"
                        variant="outlined"
                        className="sku-form-textfield-width"
                        fullWidth
                        value={longBunWidth}
                        onChange={(e) => setLongBunWidth(e.target.value)}
                        required
                        style={{ flexGrow: 1 }}
                      />
                    </Grid>
                  </Grid>
                  <Box pt={6}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="sku-form-button"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Box>
                </form>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkuForm;
