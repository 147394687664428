import React, {
  useState,
  forwardRef,
  // useEffect,
  useCallback
} from "react";
import { useDispatch } from "react-redux";
import {
  Snackbar,
  Grid,
  // LinearProgress,
  Button,
  Typography,
  Box,
  Container
} from "@mui/material";
import MUITableColumns from "./MUITableColumns/MUITableColumn";
import MUIDataTable from "mui-datatables";
import MuiAlert from "@mui/material/Alert";
import { useMediaQuery, useTheme } from "@mui/material";
import CustoToolbar from "./CustomeToolbarSelect/CustomToolbar";
import "./TruckSchedule.css";
import { getUnscheduledTruckItems } from "../../redux-sliceRoutes/truckScheduleRoutes";
import TruckScheduleDialog from "./TruckScheduleDialog";
import "./TruckSchedule.css";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TruckScheduling_v2 = () => {
  const { truckSchedulingColumns } = MUITableColumns;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [scheduleData, setscheduleData] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [missingParts, setMissingParts] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const GenerateOpenOrdersReport = useCallback(() => {
    setLoading(true);
    dispatch(getUnscheduledTruckItems()).then((response) => {
      if (response.type === "getUnscheduledTruckItems/fulfilled") {
        const missingOhmPartNumbers = response.payload.filter(
          (items) => !items.ohm_part_number
        );

        if (missingOhmPartNumbers.length > 0) {
          setDisabled(true);
          const itemsPerColumn = Math.ceil(missingOhmPartNumbers.length / 5);

          const rows = Array.from({ length: itemsPerColumn }, (_, index) =>
            missingOhmPartNumbers.slice(index * 5, (index + 1) * 5)
          );
          setMissingParts({ missing: rows, itemsPerColumn: itemsPerColumn });
        }
        setscheduleData(response.payload);
        setLoading(false);
      }
    });
  }, [dispatch]);

  /* closes snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const getOptions = () => {
    return {
      filter: true,
      selectableRows: "multiple",
      responsive: isMobile ? "scrollMaxHeight" : "standard",
      filterType: "multiselect",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustoToolbar
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          data={scheduleData}
          setSnackBarStatus={setSnackBarStatus}
          setOpenDialog={setOpenDialog}
          setFileData={setFileData}
          disabled={disabled}
        />
      )
    };
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <TruckScheduleDialog
        openDialog={openDialog}
        closeDialog={() => {
          GenerateOpenOrdersReport();
          setOpenDialog(false);
        }}
        closeCancelDialog={() => {
          setOpenDialog(false);
        }}
        setSnackBarStatus={(info) => {
          setSnackBarStatus(info);
        }}
        filterdData={fileData}
      />
      {!loading ? (
        <div className="Container">
          <Container
            maxWidth={false}
            spacing={2}
            className="truck_scheduling_v2_container"
          >
            {missingParts.missing ? (
              <Box ml={2} mr={2}>
                <Alert
                  sx={{
                    minHeight: "70px"
                  }}
                  icon={false}
                  severity="error"
                >
                  <Typography variant="h4">
                    These items can not be scheduled due to missing OHM part
                    numbers!
                  </Typography>
                  <Box m={1}>
                    {missingParts.missing.map((row, rowIndex) => (
                      <Grid container spacing={10} key={rowIndex}>
                        {row.map((item, itemIndex) => (
                          <Grid
                            item
                            xs={12 / missingParts.itemsPerRow}
                            key={itemIndex}
                            className="missing_part_grid_item"
                          >
                            <Box mb={3}>
                              <Typography className="missing_part_grid_item_text">
                                ID:&nbsp;{item.id}
                              </Typography>
                              <Typography className="missing_part_grid_item_text">
                                Part #:&nbsp;
                                {item.part_number}
                              </Typography>
                              <hr />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Box>
                </Alert>
              </Box>
            ) : (
              ""
            )}
            <MUIDataTable
              className="data-table"
              options={getOptions()}
              columns={truckSchedulingColumns}
              data={scheduleData}
              title={
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h4">Truck Scheduling</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      id="generate_open_orders_report"
                      className="generate_open_orders_report"
                      variant="outlined"
                      onClick={GenerateOpenOrdersReport}
                    >
                      Generate Open Order Report
                    </Button>
                  </Grid>
                </Grid>
              }
            />
          </Container>
        </div>
      ) : (
        <div className="Container">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "60vh" }}
          >
            <Grid item xs={6}>
              {/* <LinearProgress className="loading" />
               */}
              <div className="car-movement">
                <div className="car">🚚.. 🚛.. 🚚.. 🚛..</div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default TruckScheduling_v2;
