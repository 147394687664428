// Custom ToolBar Component for Open Orders Schedule Table which will populate when selecting rows
import React from "react";
import { Tooltip, IconButton, Button } from "@mui/material";
import { IndeterminateCheckBox, CompareArrows } from "@mui/icons-material";
import "./CustomToolbar.css";

const CustoToolbar = ({
  displayData,
  selectedRows,
  setSelectedRows,
  setFileData,
  setOpenDialog
}) => {
  const handleClickInverseSelection = () => {
    const nextSelectedRows = displayData.reduce(
      (nextSelectedRows, _, index) => {
        if (
          !selectedRows.data.find((selectedRow) => selectedRow.index === index)
        ) {
          nextSelectedRows.push(index);
        }

        return nextSelectedRows;
      },
      []
    );

    setSelectedRows(nextSelectedRows);
  };

  const handleClickDeselectAll = () => {
    setSelectedRows([]);
  };

  const handleClickSubmitSchedule = () => {
    const nextSelectedRows = displayData.reduce(
      (nextSelectedRows, _, index) => {
        if (
          selectedRows.data.find((selectedRow) => selectedRow.index === index)
        ) {
          nextSelectedRows.push(_.data);
        }

        return nextSelectedRows;
      },
      []
    );

    const transformedData = nextSelectedRows.map((item) => {
      const [
        id,
        contact_name,
        order_number,
        po_number,
        part_number,
        ohm_part_number,
        shipping_method
      ] = item;

      return {
        id,
        contact_name,
        order_number,
        po_number,
        part_number,
        ohm_part_number,
        shipping_method
      };
    });

    let filterdData = combineLikeValues(nextSelectedRows);
    setFileData({ displayData: filterdData, scheduleData: transformedData });
    setOpenDialog(true);
  };

  const combineLikeValues = (data) => {
    const combinedData = data.reduce((acc, item) => {
      if (acc[item[2]]) {
        acc[item[2]].count += 1;
      } else {
        acc[item[2]] = {
          order_number: item[2],
          customer_name: item[1],
          count: 1
        };
      }
      return acc;
    }, {});
    return Object.values(combinedData);
  };

  return (
    <div>
      <Tooltip title={"Deselect ALL"}>
        <IconButton onClick={handleClickDeselectAll}>
          <IndeterminateCheckBox />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Inverse selection"}>
        <IconButton onClick={handleClickInverseSelection}>
          <CompareArrows />
        </IconButton>
      </Tooltip>
      <Tooltip title="Review Schedule">
        <span>
          <Button
            id="submit_schedule_custom_toolbar_button"
            className="submit_schedule_custom_toolbar_button"
            onClick={handleClickSubmitSchedule}
          >
            Review Schedule
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default CustoToolbar;
