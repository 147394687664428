import React, { useState } from "react"; // Space for imports
import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "./HeaderBar.css";
import MenuBar from "./MenuBar";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { logout } from "../../admin/actions/userActions";
const BrandsLogo = require("../../media/images/3z_banner.png");

export default function Headerbar(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const THEME = createTheme({
    typography: {
      fontFamily: ["Montserrat"]
    }
  });

  const handleLogout = (e) => {
    dispatch(logout());
    navigate("/");
    navigate(0);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
    props.open(true);
  };

  return (
    <ThemeProvider theme={THEME}>
      <Box>
        <AppBar sx={{ backgroundColor: "white" }} position="fixed" open={open}>
          <Toolbar className="HeaderPP">
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <FontAwesomeIcon
                icon={faList}
                size="2x"
                color="rgba(30,50,100,255)"
              />
            </IconButton>
            <Grid className={`main_body ${open ? "open" : ""}`}>
              <Box sx={{ mr: 2, ...(open && { display: "none" }) }}>
                <img
                  alt="Company Logo"
                  style={{ maxHeight: "50px" }}
                  src={BrandsLogo}
                  className="NavIcon"
                />
              </Box>
            </Grid>

            <Box mr={8}>
              <Button
                variant="outlined"
                style={{
                  color: "white",
                  background: "rgba(0,0,0,1)",
                  fontFamily:
                    "Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif"
                }}
                onClick={(e) => handleLogout(e)}
              >
                Logout &nbsp; <FontAwesomeIcon icon={faSignOut} />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <hr className="DividerBarPP" />
        <MenuBar
          open={open}
          close={(close) => {
            setOpen(close);
            props.open(close);
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
