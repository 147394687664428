import { createAsyncThunk } from "@reduxjs/toolkit";
import schedule_service_api from "../utils/schedule_service_api";
import { getToken } from "../utils/auth";

// route for retrieving schedule by date
export const getSchedule = createAsyncThunk(
  "getSchedule",
  async (queryParams = {}, { rejectWithValue }) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/d2c_schedule/scheduled_items`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("getSchedule error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status,
          error: error.response.data
        };
        return rejectWithValue(errorPayload);
      });
  }
);

export const getScheduleQuantity = createAsyncThunk(
  "getScheduleQuantity",
  async (queryParams = {}, { rejectWithValue }) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/d2c_schedule/scheduled_items_quantity`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("getScheduleQuantity error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return rejectWithValue(errorPayload);
      });
  }
);

// route for email csv file of unscheduled items
export const emailCSVSchedule = createAsyncThunk(
  "emailCSVSchedule",
  async (queryParams = {}, { rejectWithValue }) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .get(`/d2c_schedule/email_stock_orders?`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("emailCSVSchedule error", error);
        const errorPayload = {
          message: error.response.data.detail,
          status: error.response.status
        };
        console.log(errorPayload);
        return rejectWithValue(errorPayload);
      });
  }
);

// route for submitting csv upload file
export const submitCSVSchedule = createAsyncThunk(
  "submitCSVSchedule",
  async (data, { rejectWithValue }) => {
    const { email, csvFileData, scheduled_date } = data;
    const token = await getToken();
    const params = { email, scheduled_date };

    return schedule_service_api
      .post(`/d2c_schedule/submit_schedule`, csvFileData, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: params
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorPayload = {
          message: error.response.data.detail,
          status: error.response.status
        };
        console.log(errorPayload);
        return rejectWithValue(errorPayload);
      });
  }
);

// route for soft deleting schedule items
export const unscheduleItems = createAsyncThunk(
  "unscheduleItems",
  async ({ user_email, items }, { rejectWithValue }) => {
    if (!Array.isArray(items)) {
      return rejectWithValue("Invalid data format: items should be an array");
    }
    let filteredUnscheduledItemsArray = items.filter(
      (item) => item.id !== null
    );
    // Prepare the payload
    const payload = { items: filteredUnscheduledItemsArray };
    try {
      const token = await getToken();
      const response = await schedule_service_api.post(
        `/d2c_schedule/unschedule_items/${user_email}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("unscheduleItems error", error);
      const errorPayload = {
        message: error.response?.data?.detail || "An unexpected error occurred",
        status: error.response?.status || 500
      };
      console.log("Error payload:", errorPayload);
      return rejectWithValue(errorPayload);
    }
  }
);

// route for soft deleting stock schedule items
export const unscheduleStockItems = createAsyncThunk(
  "unscheduleItems",
  async ({ user_email, items }, { rejectWithValue }) => {
    if (!Array.isArray(items)) {
      return rejectWithValue("Invalid data format: items should be an array");
    }
    let filteredUnscheduledItemsArray = items.filter(
      (item) => item.id !== null
    );
    // Prepare the payload
    const payload = { items: filteredUnscheduledItemsArray };
    try {
      const token = await getToken();
      const response = await schedule_service_api.post(
        `/d2c_schedule/unschedule_stock_items/${user_email}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("unscheduleItems error", error);
      const errorPayload = {
        message: error.response?.data?.detail || "An unexpected error occurred",
        status: error.response?.status || 500
      };
      console.log("Error payload:", errorPayload);
      return rejectWithValue(errorPayload);
    }
  }
);

// route for expoeting schedule by date to ohm
export const exportToOhm = createAsyncThunk(
  "exportToOhm",
  async (queryParams) => {
    const token = await getToken();
    if (!token) {
      throw new Error("Authentication token not found in Azure.");
    }
    return schedule_service_api
      .post(`/d2c_schedule/export_to_ohm`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("exportToOhm error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return errorPayload;
      });
  }
);

export const getScheduleOrderItemsReport = createAsyncThunk(
  "getScheduleOrderItemsReport",
  async (queryParams = {}, { getState, rejectWithValue }) => {
    // const state = getState();
    // console.log(state);
    // const { cacheTimestamp, cacheDuration, list } = state.scheduleSlice;

    // const isCacheValid = cacheTimestamp && (Date.now() - cacheTimestamp) < cacheDuration;

    // // If cache is valid, return the cached data
    // if (isCacheValid) {
    //   return list;
    // }

    const token = await getToken();
    if (!token) {
      return rejectWithValue("Authentication token not found in Azure.");
    }

    return schedule_service_api
      .get(`/schedule/schedule_report`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: queryParams
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error("getScheduleOrderItemsReport error", error);
        const errorPayload = {
          message: error.message,
          status: error.response.status
        };
        return rejectWithValue(errorPayload);
      });
  }
);
