import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LinearProgress, Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useMediaQuery, useTheme } from "@mui/material";
import ScheduleReportTableColumns from "./ScheduleReportTableColumns/ScheduleReportTableColumns";
import { getScheduleOrderItemsReport } from "../../redux-sliceRoutes/scheduleRoutes";

export const ScheduleReport = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [scheduleReportData, setScheduleReportData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setLoading(true);
    dispatch(getScheduleOrderItemsReport()).then((res) => {
      if (res.type === "getScheduleOrderItemsReport/fulfilled") {
        setScheduleReportData(res.payload);
        setLoading(false);
      }
    });
  }, [dispatch]);

  const getOptions = () => {
    return {
      filter: true,
      selectableRows: "multiple",
      responsive: isMobile ? "scrollMaxHeight" : "standard",
      filterType: "dropdown",
      rowsPerPage: 100,
      print: false,
      download: false,
      viewColumns: false
    };
  };

  return (
    <>
      {!loading ? (
        <div className="Container">
          <MUIDataTable
            className="data-table"
            title="Schedule Report"
            options={getOptions()}
            columns={ScheduleReportTableColumns}
            data={scheduleReportData}
          />
        </div>
      ) : (
        <div className="Container">
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "60vh" }}
          >
            <Grid item xs={6}>
              <LinearProgress className="loading" />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
