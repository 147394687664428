import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Input,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "./shortBunSchedule.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export function ShortBunDialog(props) {
  const [shortBunData, setShortBunData] = useState({});
  const [skuInfo, setSkuInfo] = useState([]);
  const fullWidth = true;
  const [submittedIndex, setSubmittedIndex] = useState([]);
  const [sIndex, setSIndex] = useState([]);

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setSkuInfo([]);
    setShortBunData({});
    setSubmittedIndex([]);
    setSIndex([]);
    props.close(false);
  };

  useEffect(() => {
    if (props.open) {
      const { skuData } = props;
      const { short_bun_schedule, foam_pour_schedule } = skuData[0];

      // Updating foam_relationship with corresponding quantities and heights
      foam_pour_schedule[0].foam_relationship.forEach((relation) => {
        const matchedSku = short_bun_schedule.find(
          (sku_id) => relation.child_sku[0].id === sku_id.sku_id
        );

        if (matchedSku) {
          relation.child_sku[0].quantity = matchedSku.quantity;
          if (
            matchedSku.short_bun_serial &&
            matchedSku.short_bun_serial.length > 0
          ) {
            relation.child_sku[0].long_bun_height =
              matchedSku.short_bun_serial[0].long_bun_height;
          } else {
            relation.child_sku[0].long_bun_height = null;
          }
        }
      });

      setSkuInfo(foam_pour_schedule[0].foam_relationship);
    }
  }, [props]);

  /* handles quantity change */
  const handleQtyChange = (e, idx, sku, foam_sku) => {
    setShortBunData((prevState) => ({
      ...prevState,
      [idx]: {
        sku: sku,
        quantity: parseInt(e.target.value),
        long_bun_schedule: props.skuData[0],
        long_bun_height: prevState[idx]?.long_bun_height,
        foam_sku: foam_sku
      }
    }));
  };

  const handleKeyDown = (e, idx) => {
    if (e.key === "Enter") {
      document.getElementById(`height${idx}`).focus();
    }
  };

  const handleHeightChange = (e, idx, sku, foam_sku) => {
    setShortBunData((prevState) => ({
      ...prevState,
      [idx]: {
        sku: sku,
        quantity: prevState[idx]?.quantity,
        long_bun_schedule: props.skuData[0],
        long_bun_height: parseFloat(e.target.value),
        foam_sku: foam_sku
      }
    }));
  };

  const handleIndividualAdd = (e, idx) => {
    e.preventDefault();

    if (submittedIndex.includes(idx)) return;

    const currentData = shortBunData[idx];
    const { quantity, long_bun_height } = currentData || {};

    if (!currentData) return;

    if (long_bun_height && quantity) {
      setSubmittedIndex((submitted) => [...submitted, shortBunData[idx]]);
      setSIndex((submitted) => [...submitted, idx]);

      props.addShortBunData(currentData);
    } else {
      props.shortBunError("Quantity and height are required!");
    }
  };

  const handleAllowUpdate = (e, idx) => {
    e.preventDefault();

    if (submittedIndex.includes(idx)) {
      props.shortBunError("Unable to process multiple updates!");
      return;
    }
    const currentData = shortBunData[idx];
    if (!currentData) return;

    const foamSkuId = currentData.foam_sku.id;
    const longBunSchedule = currentData.long_bun_schedule.short_bun_schedule;
    const t8SkuInfo = longBunSchedule.find((foam) => foam.sku_id === foamSkuId);

    const { quantity: currentQuantity } = currentData;
    const { quantity: foamQuantity = 0 } = t8SkuInfo || {};

    if (currentQuantity == null || foamQuantity == null) {
      return props.shortBunError("Quantity and height are required!");
    }

    if (foamQuantity < currentQuantity) {
      setSubmittedIndex((submitted) => [...submitted, idx]);
      props.updateShortBun(currentData);
    } else {
      props.shortBunError(
        "Unable to process quantity reduction. Please close form and click T8 Removal and scan label"
      );
    }
  };

  return (
    <>
      {Object.keys(props.skuData).length !== 0 ? (
        <Dialog
          open={props.open}
          onClose={handleClose}
          maxWidth={"xxl"}
          fullWidth={fullWidth}
          disableEscapeKeyDown
        >
          <DialogTitle className="dialog_title">
            Avaliable Cuts for SKU&nbsp;
            <span className="dialog_title_sku">
              {props.skuData[0].foam_pour_schedule[0].foam_sku[0].sku}
            </span>
            <Button
              size="large"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
              onClick={handleClose}
              className="addBunLineButton"
            >
              CLOSE
            </Button>
          </DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Cut</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                    <StyledTableCell>Height(inches)</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skuInfo.map((sku, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "425px" }}
                      >
                        <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {sku.child_sku[0].sku}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {sku.child_sku[0].description}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Input
                          name="qty"
                          id={sku.id}
                          inputProps={{ min: 0, max: 1000 }}
                          onKeyDown={(e) => handleKeyDown(e, idx)}
                          placeholder="0"
                          defaultValue={
                            sku.child_sku[0].quantity
                              ? sku.child_sku[0].quantity
                              : ""
                          }
                          value={shortBunData.idx}
                          onChange={(e) =>
                            handleQtyChange(e, idx, sku, sku.child_sku[0])
                          }
                          className="dialog_input_box"
                        />
                      </StyledTableCell>

                      <StyledTableCell component="th" scope="row">
                        <Input
                          id={`height${idx}`}
                          name="height"
                          className="dialog_input_box"
                          inputProps={{ min: 0, max: 1000 }}
                          defaultValue={sku.child_sku[0].long_bun_height}
                          placeholder="00.00"
                          value={shortBunData.idx}
                          onChange={(e) =>
                            handleHeightChange(e, idx, sku, sku.child_sku[0])
                          }
                        />
                      </StyledTableCell>

                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: "425px", textAlignLast: "center" }}
                      >
                        {sIndex.includes(idx) ||
                        sku.child_sku[0].long_bun_height ? (
                          <Button
                            id="t8-processing-button"
                            style={{
                              width: "225px",
                              backgroundColor: "orange",
                              color: "black"
                            }}
                            onClick={(e) =>
                              handleAllowUpdate(e, idx, sku, sku.child_sku[0])
                            }
                          >
                            Update
                          </Button>
                        ) : (
                          <Button
                            id="t8-processing-button"
                            style={{ width: "225px" }}
                            className="addBunLineButton"
                            onClick={(e) =>
                              handleIndividualAdd(e, idx, sku, sku.child_sku[0])
                            }
                            disabled={submittedIndex.includes(idx)}
                          >
                            Add
                          </Button>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
}
