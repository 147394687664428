import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Button,
  Grid,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Table,
  TableBody
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import moment from "moment";
import "./T8Reporting.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export default function T8ReportDialog(props) {
  const [t8info, setT8info] = useState([]);

  const handleClose = (event, reason) => {
    props.close(false);
  };

  useEffect(() => {
    if (props.T8Data.length && props.open === true) {
      setT8info(props.T8Data);
      let filteredData = props.T8Data;
      filteredData.filter((data) => {
        let dataObj = {
          sku: data.foam_pour_schedule[0].foam_sku[0].sku,
          serial_number: data.serial_number,
          pour_date: data.pour_date,
          total_qty: data.foam_scan_log[0] && data.foam_scan_log[0].bdft_qty,
          cut_total: data.cutTotal.toFixed(3),
          waste_qty:
            data.foam_scan_log[0] &&
            (data.foam_scan_log[0].bdft_qty - data.cutTotal.toFixed(3)).toFixed(
              3
            ),
          yield: Number(
            data.cutTotal / data.foam_scan_log[0].bdft_qty
          ).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2
          })
        };
        return dataObj;
      });
    }
  }, [props.open, props.T8Data]);

  return (
    <>
      {t8info.length ? (
        <>
          <Dialog
            data-testid="long-bun-dialog"
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="long-bun-dialog"
            PaperProps={{
              style: {
                maxWidth: "3000px ",
                width: "1200px ",
                backgroundColor: "none",
                marginTop: "-0px"
              }
            }}
          >
            <DialogContent id="long-bun-dialog-content">
              <Typography
                component={"span"}
                variant="h4"
                className="t8_production_dialog_title"
              >
                T8 Production Report Data
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Box mt={1}>
                    <Typography
                      variant="h6"
                      className="t8_production_dialog_subtitle"
                    >
                      Long Bun Data:
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      SKU:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {t8info[0].foam_pour_schedule[0].foam_sku[0].sku}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Serial Number:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {t8info[0].serial_number}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Pour Date:&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {moment(t8info[0].pour_date).format("MM-DD-YYYY")}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Total Qty(bdft):&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {t8info[0].foam_scan_log[0] &&
                          t8info[0].foam_scan_log[0].bdft_qty}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Cut Qty(bdft):&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {t8info[0].cutTotal.toFixed(3)}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Waste Qty(bdft):&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {t8info[0].foam_scan_log[0] &&
                          (
                            t8info[0].foam_scan_log[0].bdft_qty -
                            t8info[0].cutTotal.toFixed(3)
                          ).toFixed(3)}
                      </span>
                    </Typography>
                    <Typography
                      variant="h5"
                      className="t8_production_dialog_category"
                    >
                      Yield %&nbsp;
                      <span className="t8_production_dialog_category_value">
                        {Number(
                          t8info[0].cutTotal /
                            t8info[0].foam_scan_log[0].bdft_qty
                        ).toLocaleString(undefined, {
                          style: "percent",
                          minimumFractionDigits: 2
                        })}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    className="t8_production_dialog_subtitle"
                  >
                    T8 Cuts:
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="large">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Short Bun SKU</StyledTableCell>
                          <StyledTableCell align="right">
                            Quantity
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Short Bun Cut Date
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {t8info[0].short_bun_schedule_by_short_bun_id.map(
                        (T8) => (
                          <TableBody key={T8.id}>
                            <TableRow>
                              <StyledTableCell
                                className="t8_production_dialog_table_cell"
                                component="th"
                                align="left"
                              >
                                {T8.foam_sku[0].sku}
                              </StyledTableCell>
                              <StyledTableCell component="th" align="center">
                                {T8.quantity}
                              </StyledTableCell>
                              <StyledTableCell
                                className="t8_production_dialog_table_cell"
                                component="th"
                                align="center"
                              >
                                {moment(T8.date).format("MM-DD-YYYY")}
                              </StyledTableCell>
                            </TableRow>
                          </TableBody>
                        )
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="alert-button-div">
              <Button
                size="large"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
                onClick={handleClose}
                className="addBunLineButton"
              >
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        ""
      )}
    </>
  );
}
