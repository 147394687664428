import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import md5 from "md5";
const { AUTH_API_ROOT } = require("../../utils/api");

// initialize userToken from local storage
const userToken = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const initialState = {
  data: [],
  loading: false,
  error: null,
  userInfo: null,
  userToken,
  success: false
};

// Define the asynchronous thunk for fetching data

export const authSignin = createAsyncThunk("login", async (requestData) => {
  const userLogin = {
    username: requestData.username,
    password: md5(requestData.password)
  };

  const response = await fetch(AUTH_API_ROOT + "/login", {
    method: "POST",
    body: JSON.stringify(userLogin),
    credenetials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    options: {
      mode: "cors"
    }
  });
  const data = await response.json();
  console.log('authSignin',data)
  return data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Long Bun Schedule
      .addCase(authSignin.pending, (state) => {
        state.loading = true;
        state.createSignInError = null;
      })
      .addCase(authSignin.fulfilled, (state, action) => {
        console.log('auth-signin', action, state)
        state.loading = false;
        state.userInfo = action.payload;
        state.userToken = action.payload.token;
      })
      .addCase(authSignin.rejected, (state, action) => {
        state.loading = false;
        state.createSignInError = action.error.message;
      });
  }
});

// Action creators are generated for each case reducer function

export default authSlice.reducer;
