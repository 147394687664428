import React, { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Input,
  Button,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableContainer,
  TableCell,
  tableCellClasses,
  Snackbar,
  Box,
  Divider
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import MuiAlert from "@mui/material/Alert";
import { fetchFoamProcessingScheduleByDate } from "./actions/foamPourActions";
import "./foamPourProcessing.css";
import FoamPourDialog from "./foamPourDialog/foamPourDialog";
import { Parser } from "@json2csv/plainjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "18px",
    whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    whiteSpace: "nowrap"
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function FoamPourProcessing() {
  const dispatch = useDispatch();
  const [foamScheduleDate, setFoamScheduleDate] = useState("");
  const [serialNumberDataArray, setSerialNumberDataArray] = useState([]);
  const [snackBarStatus, setSnackBarStatus] = useState({
    open: false,
    message: "",
    severity: ""
  });
  const [foamPourDialogStatus, setFoamPourDialogStatus] = useState(false);
  const [selectedPourRow, setSelectedPourRow] = useState({});
  const userId = JSON.parse(window.localStorage.getItem("userId"));

  /* sets today date in date picker and schedule data date field */
  useEffect(() => {
    const todaysDate = moment().format("YYYY-MM-DD");
    setFoamScheduleDate(todaysDate);
  }, []);

  /* fetches foam pour processing schedule when date is changed */
  useEffect(() => {
    if (foamScheduleDate) {
      const foamProcessingScheduleMutation = async (data) => {
        try {
          const actionResult = await dispatch(
            fetchFoamProcessingScheduleByDate(data)
          ).unwrap();
          let mappedData = actionResult.map((data) => {
            let bdft =
              (data.long_bun_length *
                data.long_bun_width *
                data.long_bun_height) /
              144;

            return {
              ...data,
              BD_FT: bdft.toLocaleString(undefined, {
                maximumFractionDigits: 0
              })
            };
          });
          setSerialNumberDataArray(mappedData);
        } catch (err) {
          console.log("Error occurred:", err);
        }
      };

      foamProcessingScheduleMutation({ date: foamScheduleDate });
    }
  }, [foamScheduleDate, dispatch]);

  const handlePourButton = (row) => {
    row["userId"] = userId;
    setFoamPourDialogStatus((prevState) => !prevState);
    setSelectedPourRow(row);
  };

  /* handles the change of date in date picker */
  const handleDateChange = (e) => {
    setFoamScheduleDate(e.target.value);
  };

  /* closes printer error snackbar */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarStatus((prevState) => ({
      ...prevState,
      open: false
    }));
  };

  const exportToCSV = () => {
    const fields = [
      {
        label: "SKU",
        value: (row) => row.sku
      },
      { label: "Serial Number", value: (row) => `${row.serial_number}'` },
      {
        label: "Length",
        value: (row) => row.length
      },
      {
        label: "Width",
        value: (row) => row.width
      },
      {
        label: "Height",
        value: (row) => row.height
      },

      {
        label: "Status",
        value: "status"
      },
      {
        label: "Pour Date",
        value: (row) => moment(row.pour_date).format("MM-DD-YYYY")
      },
      {
        label: "BD-FT",
        value: (row) =>
          Number(
            (row.long_bun_length * row.long_bun_width * row.long_bun_height) /
              144
          ).toLocaleString(undefined, {
            maximumFractionDigits: 0
          })
      }
    ];

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(serialNumberDataArray);

    // format todays datetime for file
    const today = moment().format("YYYY-MM-DD_HH-mm-ss");
    const filename = `foam_process_report_${today}.csv`;

    // Download the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Snackbar
        open={snackBarStatus.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          className="alert-message"
          onClose={handleClose}
          severity={snackBarStatus.severity}
          sx={{ width: "100%" }}
        >
          {snackBarStatus.message}
        </Alert>
      </Snackbar>
      <FoamPourDialog
        openStatus={foamPourDialogStatus}
        setFoamPourDialogStatus={setFoamPourDialogStatus}
        setSerialNumberDataArray={setSerialNumberDataArray}
        selectedPourRow={selectedPourRow}
      />
      <div className="Container" data-testid="foam-pour-processing">
        <Box ml={6}>
          <h1 className="foam-processing-header">Foam Pour Processing</h1>

          <Grid>
            <form>
              <Grid className="foam-processing-table-grid">
                <TableContainer>
                  <Grid
                    container
                    justifyContent="space-between"
                    className="foam-processing-date-header-grid"
                  >
                    <Grid item sx={{ my: 5 }}>
                      <Typography
                        component={"span"}
                        className="foam-processing-date-header"
                      >
                        Date:
                      </Typography>
                      <Input
                        data-testid="date-input"
                        type="date"
                        className="foam-processing-date-picker"
                        value={foamScheduleDate}
                        onChange={(e) => handleDateChange(e)}
                      />
                    </Grid>
                    <Grid item sx={{ my: 5 }}>
                      <Button
                        variant="contained"
                        className="t8_button"
                        color="primary"
                        onClick={exportToCSV}
                      >
                        Export to CSV
                      </Button>
                    </Grid>
                  </Grid>
                  <Table
                    aria-label="customized table"
                    className="foam-pour-processing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>SKU</StyledTableCell>
                        <StyledTableCell>Serial Number</StyledTableCell>
                        <StyledTableCell>Length</StyledTableCell>
                        <StyledTableCell>Width</StyledTableCell>
                        <StyledTableCell>Height</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Pour Date</StyledTableCell>
                        <StyledTableCell>Ready Date</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>BD-FT</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serialNumberDataArray.map((row, idx) => (
                        <StyledTableRow key={idx}>
                          <StyledTableCell component="th" scope="row">
                            {row.sku}
                          </StyledTableCell>

                          <StyledTableCell>{row.serial_number}</StyledTableCell>
                          <StyledTableCell>
                            {row.long_bun_length} in.
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.long_bun_width} in.
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.long_bun_height} in.
                          </StyledTableCell>
                          <StyledTableCell>{row.status}</StyledTableCell>
                          <StyledTableCell>{row.pour_date}</StyledTableCell>
                          <StyledTableCell>{row.ready_date}</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          {row.status !== "COMPLETE" ? (
                            <>
                              <StyledTableCell>
                                <Button
                                  id="foam-processing-button-table"
                                  onClick={() => handlePourButton(row)}
                                  className="foam-processing-table-buttons"
                                  sx={{
                                    color: "black",
                                    "&:hover": {
                                      bgcolor: "black",
                                      color: "white",
                                      boxShadow:
                                        "0px 4px 15px rgba(0, 0, 0, 0.2)" // Shadow on hover
                                    }
                                  }}
                                >
                                  Pour
                                </Button>
                              </StyledTableCell>
                            </>
                          ) : (
                            <>
                              <StyledTableCell />
                            </>
                          )}
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>{row.BD_FT}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </form>
          </Grid>
        </Box>
      </div>
    </>
  );
}
